import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

export function useLinkRouteIntercept() {
  const navigate = useNavigate();

  // TODO: Talk to Clerk about this
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleLinkClick = (event: any) => {
      const link = event.target.closest("a");

      if (link) {
        const href = link.getAttribute("href");
        const hrefUrl = new URL(href);
        const isRelative = hrefUrl.host === window.location.host;

        if (href.startsWith("/") || isRelative) {
          event.preventDefault();
          event.stopPropagation();
          navigate(hrefUrl.pathname + hrefUrl.search + hrefUrl.hash);
        }
      }
    };

    document.addEventListener("click", handleLinkClick, true);

    return () => {
      document.removeEventListener("click", handleLinkClick, true);
    };
  }, [navigate]);
}
