import React, { memo } from "react";

import {
  IconButton,
  ButtonProps,
  ButtonGroupProps,
  IconButtonProps,
  useDisclosure
} from "@chakra-ui/react";
import { FaTrashCan } from "react-icons/fa6";

import { theme } from "../../styles/theme";
import { ConfirmModal } from "../ConfirmModal";

interface DeleteFileButtonProps {
  disabled?: boolean;
  onCancelDelete?: () => void;
  onConfirmDelete?: () => void;

  // Pass-through props
  buttonGroupProps?: ButtonGroupProps;
  buttonProps?: ButtonProps;
  iconButtonProps?: IconButtonProps;
}

export const DeleteFileButton = memo(
  ({
    disabled,
    onCancelDelete,
    onConfirmDelete,
    iconButtonProps
  }: DeleteFileButtonProps) => {
    const { isOpen, onClose, onOpen } = useDisclosure();

    return (
      <>
        <IconButton
          variant={"danger"}
          isDisabled={disabled}
          aria-label="Delete"
          icon={<FaTrashCan />}
          {...iconButtonProps}
          style={{
            transition: "background-color 0.5s ease, color 0.5s ease"
          }}
          css={{
            ":hover": {
              backgroundColor: theme.components.Button.variants.danger.bg,
              color: theme.components.Button.variants.danger.color
            }
          }}
          onClick={(e) => {
            onOpen();
            iconButtonProps?.onClick?.(e);
          }}
        />
        <ConfirmModal isOpen={isOpen} onClose={onClose} onConfirm={onConfirmDelete} onCancel={onCancelDelete} />
      </>
    );
  }
);
