import { useRef, useState } from "react";

import { Flex, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { BsCheck2Circle, BsCircle } from "react-icons/bs";
import { TbFileText, TbBrandLinkedin, TbSend } from "react-icons/tb";

import { useDebouncedResize } from "../../../hooks/useDebounceResize";
import { AthleteGridData } from "../types/athletes";

interface AthleteActionIconsProps {
    athlete: AthleteGridData;
    direction?: "row" | "column";
}

export const AthleteActionIcons = ({
    athlete,
    direction = "row"
}: AthleteActionIconsProps) => {
    const isMobile = useBreakpointValue({ base: true, md: false })
    const ref = useRef<HTMLDivElement>(null)
    const [dynamicIconSize, setDynamicIconSize] = useState(isMobile ? 24 : 32)

    const getDynamicIconSize = () => {
        if (!ref.current) return
        const iconBoxDimensions = ref.current?.getBoundingClientRect()
        let dynamicIconSize = 8 + Math.floor((direction === 'row' ? iconBoxDimensions?.width : iconBoxDimensions?.height) * 0.1)
        dynamicIconSize = Math.max(dynamicIconSize, 16)
        dynamicIconSize = Math.min(dynamicIconSize, 32)
        setDynamicIconSize(dynamicIconSize)
    }

    useDebouncedResize(getDynamicIconSize, 150)

    return (
        <Flex
            ref={ref}
            direction={direction}
            justifyContent={direction === "row" ? "space-between" : "center"}
            alignItems={direction === "row" ? "center" : "space-between"}
            {...(direction === "column" ? {
                h: "100%",
                py: 1
            } : {
                w: "100%",
                px: 1
            })}

        >
            <IconButton
                icon={<TbFileText fontSize={dynamicIconSize} />}
                aria-label="Resume"
                isDisabled={!athlete.resumeOpenUrl}
                variant="ghost"
                color={athlete.resumeOpenUrl ? "blue.500" : "gray.300"}
                onClick={() => {
                    if (athlete.resumeOpenUrl) {
                        window.open(athlete.resumeOpenUrl, '_blank', 'noopener,noreferrer')
                    }
                }}
            />
            <IconButton
                as="a"
                icon={<TbBrandLinkedin fontSize={dynamicIconSize} />}
                aria-label="LinkedIn profile"
                target="_blank"
                rel="noopener noreferrer"
                href={athlete.linkedInUrl ? (athlete.linkedInUrl.startsWith('http') ? athlete.linkedInUrl : `https://${athlete.linkedInUrl}`) : undefined}
                isDisabled={!athlete.linkedInUrl}
                variant="ghost"
                color={athlete.linkedInUrl ? "blue.500" : "gray.300"}
            />
            <IconButton
                icon={<TbSend fontSize={dynamicIconSize - 2} />}
                aria-label="Send Email"
                isDisabled={!athlete.emailAddress}
                variant="ghost"
                color={athlete.emailAddress ? "blue.500" : "gray.300"}
                onClick={() => {
                    if (athlete.emailAddress) {
                        window.location.href = `mailto:${athlete.emailAddress}`;
                    }
                }}
            />
            <Flex justifyContent="center" alignItems="center" minW={`${dynamicIconSize}px`} h={`${dynamicIconSize}px`}>
                {athlete.readyToWork ? (
                    <BsCheck2Circle fontSize={dynamicIconSize - 2} style={{ color: "green" }} />
                ) : (
                    <BsCircle fontSize={dynamicIconSize - 4} style={{ color: "red" }} />
                )}
            </Flex>
        </Flex>
    );
}; 