import { Center } from "@chakra-ui/react";
import { UserProfile } from "@clerk/clerk-react";

// import SomeText from "./components/SomeText";

const One = () => {
  return (
    <Center px={[0, 6]} pt="8">
      {/* <Text textStyle={"body"}>Coming soon...</Text> */}
      <UserProfile routing="hash" />
    </Center>
  );
};

export default One;
