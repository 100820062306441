import { useEffect, useState } from "react";

import { Center, Spinner } from "@chakra-ui/react";
import { useClerk, useUser } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";

import { RoutePath, UserRole, AthleteRole } from "../../../../../shared/models";
import { useAppStore } from "../../app-store";
import { getIsIntern, getUserInfo } from "../../services/user-info-api";

type PrivateRouteProps = {
  children: React.ReactNode;
  redirectTo?: string;
  allowedRoles: (UserRole | AthleteRole)[];
};

const RequireAuth = ({ children, redirectTo = "/signin", allowedRoles }: PrivateRouteProps) => {
  const clerk = useClerk();
  const user = useUser();
  const [hasCheckedUserInfo, setHasCheckedUserInfo] = useState(false);
  const [userInfo, setUserInfo] = useAppStore((state) => [state.userInfo, state.setUserInfo]);
  const [isIntern, setIsIntern] = useAppStore((state) => [state.isIntern, state.setIsIntern]);

  const { data } = useQuery({
    queryKey: ["userInfo"],
    queryFn: getUserInfo,
    refetchOnMount: false,
  });

  const { data: isInternData } = useQuery({
    queryKey: ["isIntern"],
    queryFn: getIsIntern,
    refetchOnMount: false,
  });

  useEffect(() => {
    if (isInternData) {
      setIsIntern(isInternData);
    }
  }, [isInternData]);

  useEffect(() => {
    if (!clerk.loaded) {
      return;
    }
    if (!user.isSignedIn) {
      setHasCheckedUserInfo(true);
      return;
    }

    if (user.isSignedIn && userInfo) {
      setHasCheckedUserInfo(true);
      return;
    }

    if (user.isSignedIn && !userInfo && data) {
      const fetchUserInfo = () => {
        setHasCheckedUserInfo(true);
        setUserInfo(data);
      };
      fetchUserInfo();
    }
  }, [clerk.loaded, user.isSignedIn, userInfo, data]);

  if (!hasCheckedUserInfo) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  }

  const isAuthenticated = user.isSignedIn;
  const hasRequiredRole = userInfo                                // We have user info
    && (                                                          // AND      
      allowedRoles.includes(userInfo.role)                        // User has one of the allowed roles
      || userInfo.role === UserRole.ADMIN                         // User is an admin
      || (allowedRoles.includes(AthleteRole.INTERN) && isIntern)  // User is an intern
    );

  if (!isAuthenticated) {
    return <Navigate to={redirectTo} />;
  }

  if (!hasRequiredRole) {
    return <Navigate to={RoutePath.ROOT} />;
  }

  return children as React.ReactElement;
};

export default RequireAuth;
