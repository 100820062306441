import React, { useState } from 'react';

import { Box, Image, Modal, ModalOverlay, ModalContent, ModalCloseButton, useDisclosure, Card, CardHeader, CardBody, Heading } from '@chakra-ui/react';

interface VideoTileProps {
    thumbnailUrl: string;
    videoUrl: string;
    alt: string;
}

const VideoTile: React.FC<VideoTileProps> = ({ thumbnailUrl, videoUrl, alt }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [videoId, setVideoId] = useState<string | null>(null);

    const handleClick = () => {
        const match = videoUrl.match(/(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/watch\?.+&v=))([\w-]{11})/);
        if (match) {
            setVideoId(match[1]);
            onOpen();
        }
    };

    return (
        <>
            <Card
                onClick={handleClick}
                cursor="pointer"
                overflow="hidden"
                boxShadow="md"
                transition="all 0.3s"
                _hover={{ transform: 'scale(1.05)' }}
            >
                <CardHeader p={4} bg="brand.primary" textColor="white">
                    <Heading size="md" textAlign="center">Welcome to HCA!</Heading>
                </CardHeader>
                <CardBody p={0}>
                    <Box position="relative">
                        <Image src={thumbnailUrl} alt={alt} />
                        <Box
                            position="absolute"
                            top="15%"
                            left="15%"
                            transform="translate(-50%, -50%)"
                            width={["50px"]}
                            height={["50px"]}
                            borderRadius="50%"
                            bg="rgba(0,0,0,0.7)"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Box
                                as="span"
                                borderLeft="20px solid white"
                                borderTop="12px solid transparent"
                                borderBottom="12px solid transparent"
                                ml="5px"
                            />
                        </Box>
                    </Box>
                </CardBody>
            </Card>

            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <Box as="iframe"
                        title="YouTube video player"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        width="100%"
                        height="400px"
                        allowFullScreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    />
                </ModalContent>
            </Modal>
        </>
    );
};

export default VideoTile;
