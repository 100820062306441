import { useEffect } from "react";

import { RichWindow } from "../../globals";

interface Props {
  emailAddress: string;
  onLoad?: () => void;
}

const TallyForm = ({ emailAddress, onLoad }: Props) => {
  // The code below will load the embed
  useEffect(() => {
    const widgetScriptSrc = "https://tally.so/widgets/embed.js";

    const load = () => {
      // Load Tally embeds
      if (RichWindow.Tally !== undefined) {
        setTimeout(() => {
          RichWindow.Tally.loadEmbeds();
          if (onLoad) {
            setTimeout(onLoad, 1000);
          }
        });
        return;
      }

      // Fallback if window.Tally is not available
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      document.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((iframeEl: any) => {
        iframeEl.src = iframeEl.dataset.tallySrc;
      });
    };

    // If Tally is already loaded, load the embeds
    if (RichWindow.Tally !== undefined) {
      load();
      return;
    }

    // If the Tally widget script is not loaded yet, load it
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement("script");
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
      return;
    } else {
      load();
    }
  }, []);

  return (
    <div>
      <iframe
        data-tally-src={`https://tally.so/embed/mOzkR8?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&email_address=${emailAddress}`}
        loading="lazy"
        width="100%"
        height="216"
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
        title="HCA Portal Feedback"
      ></iframe>
    </div>
  );
};

export default TallyForm;
