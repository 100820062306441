import { Card, Box, Avatar, CardHeader, Heading, CardBody, VStack, HStack, Text, Stack } from "@chakra-ui/react";

interface ProfileCardProps {
  url: string;
  displayName?: string;
  primarySport?: string;
  university?: string;
}

const AthleteOverviewCard: React.FC<ProfileCardProps> = ({ url, displayName, primarySport, university }) => {

  return (
    <Card size="lg" w="full" overflow={"hidden"}>
      <Box
        bg="blackAlpha.300"
        w="full"
        h={["60px", 120, 120]}
        alignContent={"flex-end"}
        px={6}
      >
        <HStack justifyContent={"space-between"}>
          <Avatar
            size={["xl", "2xl"]}
            src={url}
            bg="brand.primary"
            top={[5, 12]}
            boxShadow='lg'
          />
        </HStack>
      </Box>
      <CardHeader paddingBottom={1} paddingTop={16}>
        <Heading size='xl'>{displayName}</Heading>
      </CardHeader>
      <CardBody paddingTop={0}>
        <VStack gap={0} w="full" alignItems="flex-start">
          <Stack direction={["column", "row"]} w="full">
            <Heading size='md' color={"brand.primary"}>
              {primarySport}
            </Heading>
          </Stack>
          <Text mt={[2]} size='sm'>{university}</Text>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default AthleteOverviewCard;