import { Card, CardBody, CardHeader, Center, Heading, Text } from "@chakra-ui/react";
import { UserProfileInfo } from "@shared/models";

import AthleteAboutCard from "../../components/cards/AthleteAboutCard";
import AthleteCareerCard from "../../components/cards/AthleteCareerCard";
import AthleteHcaCard from "../../components/cards/AthleteHcaCard";
import AthletePreferred from "../../components/cards/AthletePreferred";
import AthleteProfileCard from "../../components/cards/AthleteProfileCard";
import { Page } from "../../components/Page";
import { calculateProfileCompletion } from "../../utils";

type AthleteProfileProps = {
  profileData: UserProfileInfo;
  profileImage: { url: string } | null;
};

export function AthleteProfile({ profileData, profileImage }: AthleteProfileProps) {

  const profileCompletion = calculateProfileCompletion(profileData, "athlete");


  if (profileCompletion < 50) {
    return (
      <Page>
        <AthleteProfileCard profileData={profileData} profileImage={profileImage} />
        <Center h="400px" w="full">
          <Card>
            <CardHeader>
              <Heading fontSize="lg" textAlign="center">🚧 Profile under constuction 🚧</Heading>
            </CardHeader>
            <CardBody>
              <Text fontSize="lg" textAlign="center">
                Come back soon to see something awesome</Text>
            </CardBody>
          </Card>
        </Center>
      </Page>
    );
  }

  return (
    <Page>
      <AthleteProfileCard profileData={profileData} profileImage={profileImage} />

      {profileData?.hcaPersonalTagline && <AthleteHcaCard profileData={profileData} />}

      <AthleteAboutCard profileData={profileData} />

      <AthleteCareerCard profileData={profileData} />

      <AthletePreferred profileData={profileData} />
    </Page>
  );
}