import { Box, Button, Grid, Heading, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { RoutePath } from "../../../../../shared/models";

const Page404 = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => navigate(RoutePath.ROOT);

  return (
    <Grid gap={4} textAlign="center" p="10">
      <Heading>Page not Found</Heading>

      <Box maxWidth={[280, 400]} marginX="auto">
        <Image width={400} src="/assets/404 Error-rafiki.svg" />
      </Box>

      <Box>
        <Button onClick={handleBackToHome}>Go to your dashboard</Button>
      </Box>
    </Grid>
  );
};

export default Page404;
