import { Card, Box, VStack, CardHeader, Heading, CardBody, Text, HStack } from "@chakra-ui/react";

import { UserProfileInfo } from "../../../../../shared/models";
import { HcaLogo } from "../HcaLogo";

interface Props {
  isSoftened?: boolean;
  profileData: UserProfileInfo;
}

const AthleteHcaCard = ({ isSoftened = false, profileData }: Props) => {

  if (profileData.hcaPersonalTagline.length === 0) {
    return (
      <Card size="lg" w="full">
        <CardHeader>
          <HStack>
            <Heading size='md'>Your Competitive Advantage</Heading>
          </HStack>
        </CardHeader>
      </Card>
    );
  }

  return (
    <Card size="lg" w="full" bg={!isSoftened ? "brand.primary" : "white"} direction={"row"} color={!isSoftened ? "white" : "black"}>
      <Box paddingLeft={6} alignContent={"center"}>
        <HcaLogo color={!isSoftened ? "white" : "brand.200"} boxSize={[38, '65px']} />
      </Box>
      <VStack alignItems="flex-start">
        <CardHeader paddingBottom={2}>
          <Heading size='md'>Her Competitive Advantage</Heading>
        </CardHeader>
        <CardBody paddingTop={2}>
          <Text>
            {profileData.hcaPersonalTagline}
          </Text>
        </CardBody>
      </VStack>
    </Card>
  );
};

export default AthleteHcaCard;