import { FileUploadMetadata } from "@shared/models";
import axios from "axios";

import { AppConfig } from "./app-config-service";

export const getFile = async (category: string) => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/file/${category}`);
  return data as FileUploadMetadata | undefined;
};

export const postFileData = async (metadata: FileUploadMetadata, category: string) =>
  await axios.post(`${AppConfig.apiUrl}/api/file/${category}`, { ...metadata });

export const deleteFileData = async (category: string) =>
  await axios.delete(`${AppConfig.apiUrl}/api/file/${category}`);

export const getProfileImage = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile/avatar`);
  return data;
};

export const getSpecificProfileImage = async (userId: number) => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile/${userId}/avatar`);
  return data;
};