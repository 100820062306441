import { Flex } from "@chakra-ui/react";
import { SignIn, SignedIn, SignedOut } from "@clerk/clerk-react";
import { Navigate, useLocation } from "react-router-dom";

import { RoutePath } from "../../../../shared/models";
import { useLinkRouteIntercept } from "../hooks/useLinkRouteIntercept";

const SignInPage = () => {
  const location = useLocation();
  useLinkRouteIntercept();

  const redirectTo = new URLSearchParams(location.search).get("redirectTo");
  const fullRedirectTo = redirectTo ? `?redirectTo=${redirectTo}` : "";

  return (
    <Flex height={"100dvh"} justify={"center"} align={["top", "center"]}>
      <SignedOut>
        <SignIn routing="hash" signUpUrl={`/signup${fullRedirectTo}`} forceRedirectUrl={redirectTo} />
      </SignedOut>
      <SignedIn>
        <Navigate to={redirectTo || RoutePath.ROOT} />
      </SignedIn>
    </Flex>
  );
};

export default SignInPage;
