import { VStack, Heading, Tooltip, Icon } from "@chakra-ui/react";
import { IoIosInformationCircleOutline } from "react-icons/io";

interface TextWithSmallLabelProps {
  label: string;
  text: string | number;
  tooltipLabel?: string;
}

const TextWithSmallLabel: React.FC<TextWithSmallLabelProps> = ({ label, text, tooltipLabel }) => {
  return (
    <VStack alignItems="flex-start" gap={1} w="full">

      <Heading fontSize='sm'>
        {`${label}  `}
        {tooltipLabel && (
          <Tooltip label={tooltipLabel} placement="top">
            <span>
              <Icon as={IoIosInformationCircleOutline} />
            </span>
          </Tooltip>
        )}
      </Heading>

      <Heading fontSize='md'>{text}</Heading>
    </VStack>
  );
};

export default TextWithSmallLabel;