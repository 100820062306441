import { useEffect } from "react";

import { simpleDebounce } from "../utils";

export const useDebouncedResize = (callback: () => void, delay: number = 150) => {
    useEffect(() => {
        const debouncedResize = simpleDebounce(callback, delay);

        window.addEventListener('resize', debouncedResize);

        return () => {
            window.removeEventListener('resize', debouncedResize);
            debouncedResize.cancel(); // Cancel any pending debounce calls
        };
    }, [callback, delay]);
};