import React, { useState, useCallback, forwardRef, useImperativeHandle, useEffect } from 'react';

import { IFilterParams, IDoesFilterPassParams } from '@ag-grid-community/core';
import { useGridFilter } from '@ag-grid-community/react';
import { Box, Radio, RadioGroup, Text, Heading, Grid, GridItem } from "@chakra-ui/react";

interface CustomFilterProps extends IFilterParams {
    model: FilterModel | null;
    onModelChange: (model: FilterModel | null) => void;
}

interface FilterModel {
    resumeFilter: string;
    linkedInFilter: string;
    readyToWorkFilter: string;
    isPendingFilter: string;
}

const ActionsFilter = forwardRef((props: CustomFilterProps, ref) => {
    const [model, setModelState] = useState<FilterModel>({
        resumeFilter: 'none',
        linkedInFilter: 'none',
        readyToWorkFilter: 'none',
        isPendingFilter: 'none',
    });

    useEffect(() => {
        if (props.model) {
            setModelState(props.model);
        }
    }, [props.model]);

    const onChange = (key: keyof FilterModel, value: string) => {
        const newModel = { ...model, [key]: value };
        setModelState(newModel);
        props.onModelChange(isFilterActive(newModel) ? newModel : null);
    };

    const isFilterActive = (filterModel: FilterModel) => {
        return filterModel.resumeFilter !== 'none' || filterModel.linkedInFilter !== 'none' || filterModel.readyToWorkFilter !== 'none' || filterModel.isPendingFilter !== 'none';
    };

    const doesFilterPass = useCallback((params: IDoesFilterPassParams) => {
        const { data } = params.node;
        if (model.resumeFilter === 'yes' && !data.resumeMeta) return false;
        if (model.resumeFilter === 'no' && data.resumeMeta) return false;
        if (model.linkedInFilter === 'yes' && !data.linkedInUrl) return false;
        if (model.linkedInFilter === 'no' && data.linkedInUrl) return false;
        if (model.readyToWorkFilter === 'yes' && !data.readyToWork) return false;
        if (model.readyToWorkFilter === 'no' && data.readyToWork) return false;
        if (model.isPendingFilter === 'yes' && !data.isPending) return false;
        if (model.isPendingFilter === 'no' && data.isPending) return false;
        return true;
    }, [model]);

    const getModel = useCallback(() => {
        return isFilterActive(model) ? model : null;
    }, [model]);

    const setModel = useCallback((filterModel: FilterModel | null) => {
        if (filterModel) {
            setModelState(filterModel);
        } else {
            setModelState({ resumeFilter: 'none', linkedInFilter: 'none', readyToWorkFilter: 'none', isPendingFilter: 'none' });
        }
    }, []);

    useImperativeHandle(ref, () => ({
        isFilterActive: () => isFilterActive(model),
        doesFilterPass,
        getModel,
        setModel,
    }));

    useGridFilter({ doesFilterPass });

    return (
        <Box padding={4} rounded={"lg"} border={"1px"} borderColor={"gray.200"}>
            <Grid templateColumns="repeat(4, 1fr)" gap={2} width="100%">
                <GridItem>
                    <Heading size="sm" textAlign={"center"}>Clear</Heading>
                </GridItem>
                <GridItem>
                    <Heading size="sm" textAlign={"center"}>Yes</Heading>
                </GridItem>
                <GridItem>
                    <Heading size="sm" textAlign={"center"}>Filter</Heading>
                </GridItem>
                <GridItem>
                    <Heading size="sm" textAlign={"center"}>No</Heading>
                </GridItem>
                <GridItem colSpan={4}>
                    <RadioGroup onChange={(value) => onChange('resumeFilter', value)} value={model.resumeFilter} bg="gray.100" width="100%">
                        <Grid templateColumns="repeat(4, 1fr)" gap={2} width="100%">
                            <GridItem textAlign={"center"}>
                                <Radio value="none"></Radio>
                            </GridItem>
                            <GridItem textAlign={"center"}>
                                <Radio value="yes"></Radio>
                            </GridItem>
                            <GridItem textAlign={"center"}>
                                <Text size="md">Resume</Text>
                            </GridItem>
                            <GridItem textAlign={"center"}>
                                <Radio value="no"></Radio>
                            </GridItem>
                        </Grid>
                    </RadioGroup>
                </GridItem>
                <GridItem colSpan={4}>
                    <RadioGroup onChange={(value) => onChange('linkedInFilter', value)} value={model.linkedInFilter}>
                        <Grid templateColumns="repeat(4, 1fr)" gap={2} width="100%">
                            <GridItem textAlign={"center"}>
                                <Radio value="none"></Radio>
                            </GridItem>
                            <GridItem textAlign={"center"}>
                                <Radio value="yes"></Radio>
                            </GridItem>
                            <GridItem textAlign={"center"}>
                                <Text size="md">LinkedIn</Text>
                            </GridItem>
                            <GridItem textAlign={"center"}>
                                <Radio value="no"></Radio>
                            </GridItem>
                        </Grid>
                    </RadioGroup>
                </GridItem>
                <GridItem colSpan={4}>
                    <RadioGroup onChange={(value) => onChange('readyToWorkFilter', value)} value={model.readyToWorkFilter}>
                        <Grid templateColumns="repeat(4, 1fr)" gap={2} width="100%">
                            <GridItem textAlign={"center"}>
                                <Radio value="none"></Radio>
                            </GridItem>
                            <GridItem textAlign={"center"}>
                                <Radio value="yes"></Radio>
                            </GridItem>
                            <GridItem textAlign={"center"}>
                                <Text size="md">Ready?</Text>
                            </GridItem>
                            <GridItem textAlign={"center"}>
                                <Radio value="no"></Radio>
                            </GridItem>
                        </Grid>
                    </RadioGroup>
                </GridItem>
                <GridItem colSpan={4}>
                    <RadioGroup onChange={(value) => onChange('isPendingFilter', value)} value={model.isPendingFilter}>
                        <Grid templateColumns="repeat(4, 1fr)" gap={2} width="100%">
                            <GridItem textAlign={"center"}>
                                <Radio value="none"></Radio>
                            </GridItem>
                            <GridItem textAlign={"center"}>
                                <Radio value="yes"></Radio>
                            </GridItem>
                            <GridItem textAlign={"center"}>
                                <Text size="md">Pending?</Text>
                            </GridItem>
                            <GridItem textAlign={"center"}>
                                <Radio value="no"></Radio>
                            </GridItem>
                        </Grid>
                    </RadioGroup>
                </GridItem>
            </Grid>
        </Box>
    );
});

export default ActionsFilter;