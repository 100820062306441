import { Card, Box, Avatar, CardHeader, Heading, CardBody, VStack, HStack, Text, Stack, useBreakpointValue } from "@chakra-ui/react";
import { UserProfileInfo, UserRole } from "@shared/models";

import { useAppStore } from "../../app-store";
import { useUniversity } from "../../hooks/useUniversity";

interface ProfileCardProps {
  profileData: UserProfileInfo;
  profileImage: { url: string } | null;
}

const AthleteProfileCard: React.FC<ProfileCardProps> = ({ profileData, profileImage }) => {
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { university } = useUniversity(profileData?.universityId);

  if (!profileData) {
    return null;
  }

  let profileName = profileData?.displayName ? profileData?.displayName : "Athlete";

  if (userInfo?.role === UserRole.RECRUITER) {
    profileName = profileData?.displayName ? profileData?.displayName.split(' ')[0] : "Athlete";
  }

  return (
    <Card size="lg" w="full" overflow={"hidden"}>
      <Box
        bg="blackAlpha.300"
        w="full"
        h={["60px", 120, 120]}
        alignContent={"flex-end"}
        px={6}
      >
        <HStack justifyContent={"space-between"}>
          <Avatar
            size={["xl", "2xl"]}
            src={profileImage?.url}
            bg="brand.primary"
            top={[5, 12]}
            boxShadow='lg'
          />
        </HStack>
      </Box>
      <CardHeader paddingBottom={1} paddingTop={16}>
        <Heading size='xl'>{profileName}</Heading>
      </CardHeader>
      <CardBody paddingTop={0}>
        <VStack gap={0} w="full" alignItems="flex-start">
          <Stack direction={["column", "row"]} w="full">
            <Heading size='md' color={"brand.primary"}>
              {profileData?.athleticLevel} {profileData?.primarySport}
            </Heading>
            {profileData?.athleticAchievements?.slice(0, 3).map((item) => (
              <Text size='md' key={item.id} color={"blackAlpha.500"}>{`${isMobile ? "" : "| "} ${item.achievement}`}</Text>
            ))}
          </Stack>
          <Text mt={[2]} size='sm'>{university?.universityName}</Text>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default AthleteProfileCard;