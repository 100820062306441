import axios from "axios";

import { AppConfig } from "./app-config-service";
import { CompanyData, CompanyProfileInfo } from "../../../../shared/companyModels";
import { CompanyEditableFields, CompanyUserProfileEditableFields } from "../../../server/db/schema";

export const getCompanyProfile = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/company/profile`);
  return data as CompanyProfileInfo;
};

export const postCompanyProfile = async (profileInfo: CompanyProfileInfo) => {
  return await axios.post(`${AppConfig.apiUrl}/api/company/profile`, profileInfo, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export const getSpecificCompanyProfile = async (userId: number) => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/company/profile/${userId}`);
  return data as CompanyProfileInfo;
};

export const postCompany = async (company: CompanyEditableFields) => {
  return await axios.post(`${AppConfig.apiUrl}/api/company`, company, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export const getCompanies = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/companies`);
  return data as CompanyData[];
}

export const postCompanyUser = async (companyUser: CompanyUserProfileEditableFields, companyId: number) => {
  return await axios.post(`${AppConfig.apiUrl}/api/company-user`, { companyUser, companyId }, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}