import axios from "axios";

import { AppConfig } from "./app-config-service";
import { UserInfo, UserRole } from "../../../../shared/models";

export const getUserInfo = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/user-info`);
  return data;
};

export const postNewUserEmailWithRole = async (newUser: { email: string, role: UserRole, universityId?: number }) => {
  return await axios.post(`${AppConfig.apiUrl}/api/add-user-role`, newUser, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export const updateUserEmailWithRole = async (newUser: { email: string, role: UserRole, universityId?: number }) => {
  return await axios.post(`${AppConfig.apiUrl}/api/update-user-role`, newUser, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export const getUsers = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/users`);
  return data as UserInfo[];
};

export const getUsersFromClerk = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/users-clerk`);
  return data as UserInfo[];
};

export const getIsIntern = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/is-intern`);
  return data as boolean;
};
