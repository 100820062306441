import { useEffect, useState } from "react";

import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Show,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

import { AthletesTab } from "./AthletesTab";
import { CompaniesTab } from "./CompaniesTab";
import { InsightsTab } from "./InsightsTab";
import { UniversitiesTab } from "./UniversitiesTab";
import { initializeAgGrid } from "../../ag-grid-setup";
import useUrlParameter from "../../hooks/useUrlParameter";

initializeAgGrid();

// Tab mapping for url readability
const tabMapping: string[] = ["athletes", "companies", "universities", "insights"];
const getTabIndex = (tab: string) => tabMapping.findIndex((tabName) => tabName === tab);

export interface AdminUrlParameters {
  [key: string]: string;
  tab: string;
  university: string;
  athlete: string;
  deepSearch: string;
}

export const AdminPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { getAllUrlParameters, clearUrlParameter } = useUrlParameter();

  const allUrlParameters = getAllUrlParameters() as AdminUrlParameters;
  const initialTabIndex = getTabIndex(allUrlParameters.tab || "athletes");
  const [tabIndex, setTabIndex] = useState(initialTabIndex);

  useEffect(() => {
    const currentParams = getAllUrlParameters()
    const currentTab = currentParams.tab

    if (!currentTab) {
      setTabIndex(0)
      navigate(`?tab=athletes`, { replace: true });
      return
    }
    if (!tabMapping.includes(currentTab)) {
      clearUrlParameter('tab')
      setTabIndex(0)
      navigate(`?tab=athletes`, { replace: true });
      return
    }

  }, [tabIndex, navigate, location.search]);

  const handleTabChange = (index: number) => {
    setTabIndex(index)
    navigate(`?tab=${tabMapping[index]}`, { replace: true });
  }

  return (
    <Tabs
      index={tabIndex}
      onChange={(index) => handleTabChange(index)}
      isFitted={isMobile}
      position="relative"
      variant="enclosed"
      size={["sm", "lg"]}
    >
      <TabList>
        <Tab>Athletes</Tab>
        <Tab>Companies</Tab>
        <Tab>Universities</Tab>
        <Show above="sm">
          <Tab>Insights</Tab>
        </Show>
      </TabList>
      <TabPanels>
        <TabPanel px={0}>
          <AthletesTab urlParameters={allUrlParameters} />
        </TabPanel>
        <TabPanel px={0}>
          <CompaniesTab isActive={tabIndex === 1} />
        </TabPanel>
        <TabPanel px={0}>
          <UniversitiesTab isActive={tabIndex === 2} />
        </TabPanel>
        <Show above="sm">
          <TabPanel px={0}>
            <InsightsTab />
          </TabPanel>
        </Show>
      </TabPanels>
    </Tabs>
  );
};
