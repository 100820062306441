import { useState, useEffect } from "react";

import {
  Box,
  Heading,
  Text,
  Input,
  VStack,
  Container,
  Button,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  HStack,
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import AthleteOverviewCard from "../../components/cards/AthleteOverviewCard";
import { useUniversity } from "../../hooks/useUniversity";
import { copyPendingProfileToUserProfile, getPendingProfilesByDomain } from "../../services/athlete-api";

export const PendingAthletePage = ({ onClose }: { onClose: () => void }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const queryClient = useQueryClient();
  const toast = useToast();
  const { user } = useUser();

  useEffect(() => {
    if (user && user.lastName) {
      setSearchQuery(user.lastName);
    }
  }, [user]);

  const { data: pendingProfiles } = useQuery({
    queryKey: ["pendingProfiles"],
    queryFn: () => getPendingProfilesByDomain("iu.edu"),
    refetchOnMount: false,
  });

  const { mutate: pendingProfileCopy, isPending: pendingProfileCopyPending } = useMutation({
    mutationFn: copyPendingProfileToUserProfile,
    onSuccess() {
      toast({ title: "Profile claimed", status: "success", position: "top", duration: 1000 });
      queryClient.invalidateQueries({ queryKey: ["profileData"], exact: true });
      queryClient.invalidateQueries({ queryKey: ["profileImage"], exact: true });
    },
    onError(error) {
      console.error("error", error);
      toast({
        title: "Error!",
        description: "There was an issue calling the server. Refresh and try again.",
        status: "error",
        position: "top",
        duration: 3000,
      });
      onClose();
    },
  });

  const { university } = useUniversity(pendingProfiles?.[0]?.universityId ?? null);

  const filteredProfiles = pendingProfiles?.filter((profile) =>
    profile.displayName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filterToProfile = (pendingProfileId: number) => {
    setSearchQuery(pendingProfiles?.find((profile) => profile.id === pendingProfileId)?.displayName ?? "");
  };

  const handleClaimProfile = (pendingProfileId: number) => {
    pendingProfileCopy(pendingProfileId);
    if (onClose) onClose();
  };

  const handleNewProfile = () => {
    if (onClose) onClose();
  };
  return (
    <Container maxWidth="container.md" py={8}>
      <VStack spacing={6} align="stretch">
        <Heading as="h1" size="xl" textAlign="center">
          Welcome to HCA
        </Heading>

        <Text fontSize="lg" textAlign="center">
          We think we have a head start on your profile from your school website.
        </Text>

        <Text fontSize="lg" fontWeight="bold" textAlign="left" mt={8}>
          Search your name to claim your profile.
        </Text>

        <Box>
          <Input
            placeholder="Enter your name"
            value={searchQuery}
            onChange={handleSearchChange}
            colorScheme="brand"
            size="lg"
          />
        </Box>

        {searchQuery && (
          <VStack spacing={4} mt={4}>
            {filteredProfiles && filteredProfiles.length > 0 ? (
              filteredProfiles.map((profile) => (
                <Box key={profile.id} position="relative" width="100%">
                  <AthleteOverviewCard
                    url={profile.url}
                    displayName={profile.displayName}
                    primarySport={profile.primarySport}
                    university={university?.universityName}
                  />
                  <Popover placement="top">
                    {({ onClose }) => (
                      <>
                        <PopoverTrigger>
                          <Button
                            colorScheme="brand"
                            size="lg"
                            position="absolute"
                            bottom={[40, 8]}
                            right={8}
                            zIndex={1}
                            onClick={() => filterToProfile(profile.id)}
                          >
                            Claim Profile
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverHeader>
                            <Heading as="h3" size="md" textAlign="center">
                              We just want to be sure.
                            </Heading>
                          </PopoverHeader>
                          <PopoverBody textAlign="center" py={4}>
                            Are you sure this is your profile?
                          </PopoverBody>
                          <PopoverFooter py={4}>
                            <HStack spacing={4}>
                              <Button
                                colorScheme="green"
                                size="md"
                                onClick={() => handleClaimProfile(profile.id)}
                                isLoading={pendingProfileCopyPending}
                              >
                                Yep, let's go!
                              </Button>
                              <Button
                                variant="outline"
                                colorScheme="red"
                                size="md"
                                onClick={() => {
                                  setSearchQuery(user?.lastName ?? "");
                                  onClose();
                                }}
                              >
                                Oops, not me!
                              </Button>
                            </HStack>
                          </PopoverFooter>
                        </PopoverContent>
                      </>
                    )}
                  </Popover>
                </Box>
              ))
            ) : (
              <VStack>
                <Text>We could not find a profile that matched "{searchQuery}"</Text>
                <Text
                  as="button"
                  color="brand.400"
                  fontWeight="semibold"
                  _hover={{ textDecoration: "underline" }}
                  onClick={handleNewProfile}
                  disabled={pendingProfileCopyPending}
                >
                  Start with a blank profile.
                </Text>
              </VStack>
            )}
          </VStack>
        )}

        <Box alignSelf="flex-end" mt={16} mb={16}>
          <Button
            colorScheme="brand"
            size="md"
            variant="outline"
            onClick={handleNewProfile}
            isLoading={pendingProfileCopyPending}
          >
            Start with a blank profile
          </Button>
        </Box>
      </VStack>
    </Container>
  );
};
