import { AiConversation, AiConversationResponse, AiConversationUserAnswerRequest, HighlightBullet } from "@shared/models";
import axios from "axios";

import { AppConfig } from "./app-config-service";

export const getConversation = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/conversation`);

  return data as AiConversationResponse;
};

export const updateConversation = async (req: { route: string, data: { [key: string]: string[] | number | AiConversationUserAnswerRequest } }) => {
  const response = await axios.post(`${AppConfig.apiUrl}/api/conversation/${req.route}`, req.data);
  return response.data as AiConversationResponse;
}

export const replyConversation = async (requestData: AiConversationUserAnswerRequest) => {
  const response = await axios.post(`${AppConfig.apiUrl}/api/conversation/reply`, requestData);
  return response.data as AiConversation;
}

export const getHighlightBullets = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile/highlight-bullets`);
  return data as HighlightBullet[] | undefined;
};

export const updateUserHighlightBullets = async (bullets: Partial<HighlightBullet>[]) => {
  const response = await axios.post(`${AppConfig.apiUrl}/api/profile/highlight-bullets`, bullets);
  return response.data as HighlightBullet;
}