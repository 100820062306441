import { forwardRef } from "react";

import { Textarea, TextareaProps } from "@chakra-ui/react";
import ResizeTextarea from "react-textarea-autosize";

export const AutoResizeTextarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  return (
    <Textarea
      minH="unset"
      overflow="hidden"
      variant={"outline"}
      _focus={{ borderColor: "brand.primary" }}
      sx={{ borderColor: "gray.300", borderWidth: "2px" }}
      w="100%"
      resize="none"
      ref={ref}
      minRows={1}
      as={ResizeTextarea}
      {...props}
    />
  );
});
