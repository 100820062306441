import { useEffect, useRef, useState } from "react";

import { Box, Center, Container, Heading, Link, Spinner, Text, VStack } from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { motion } from "framer-motion";

import TallyForm from "./TallyForm";

const FeedbackPage = () => {
  const user = useUser();
  const email = user.user?.primaryEmailAddress?.emailAddress || "";
  const [formIsLoaded, setFormIsLoaded] = useState(false);
  const [showFormLink, setShowFormLink] = useState(false);
  const loadTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    loadTimer.current = setTimeout(() => {
      setShowFormLink(true);
    }, 3000);
    return () => clearTimeout(loadTimer.current as NodeJS.Timeout);
  }, []);

  return (
    <Container maxWidth={"80ch"} p={[1]}>
      <Heading fontSize="4xl" fontWeight="bold" pt={3}>
        Help us get better
      </Heading>
      <VStack gap={5} align={"flex-start"} pt={3}>
        <Text size="md" justifyContent={"left"}>
          The system is broken. Not just for female athletes, but for so many who go outside the box to try to do
          something great and can't quantify their experience with a bullet point full of corporate jargon.
        </Text>
        <Text size="md">Just like you, we aren't settling for where we are.</Text>
        <Text size="md" whiteSpace={"pre-wrap"}>
          Walk on? They didn't know. I'm not done yet. <br></br>
          Scholarship? Good, not done yet. <br></br>
          Getting minutes? Better, still not done. <br></br>
          Starter? Awesome, but there's more. <br></br>
          All conference? I'm honored, I'm not finished. <br></br>
          All American? Not. Done. Yet.<br></br>
          Championship? Well done. Let's do it again.
        </Text>
        <Text size="md">
          What's your idea? We want to hear it. When you join HCA, you become part of the team that helps us deliver the
          best product for you and future athletes.{" "}
        </Text>
        {/* <Button variant={"cta"} onClick={() => openFeedbackPage()}>
          Give us feedback
        </Button> */}

        {showFormLink && !formIsLoaded ? (
          <Box p="3" bg="#FFDEB3" borderRadius={3}>
            Unfortunately we weren't able to load our feedback form, but feel free to use our{" "}
            <Link href="https://www.joinher.com/#comp-jusfdoho" target="_blank">
              Get In Touch
            </Link>{" "}
            form to tell us what's on your mind.
          </Box>
        ) : (
          <>
            <Box position={"relative"} w="100%">
              <motion.div
                style={{ width: "100%" }}
                initial={{ opacity: 1 }}
                animate={{ opacity: formIsLoaded ? 0.01 : 1 }}
                transition={{ duration: 0.5 }}
              >
                <Center position={"absolute"} w="100%">
                  <Spinner />
                </Center>
              </motion.div>
              <motion.div
                style={{ width: "100%" }}
                initial={{ opacity: 0.01 }}
                animate={{ opacity: formIsLoaded ? 1 : 0.01 }}
                transition={{ duration: 1.5 }}
              >
                <TallyForm emailAddress={email} onLoad={() => setFormIsLoaded(true)} />
              </motion.div>
            </Box>
          </>
        )}
      </VStack>
    </Container>
  );
};

export default FeedbackPage;
