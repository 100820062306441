import { UserInfo } from "@shared/models";
import { create } from "zustand";

interface AppState {
  userInfo?: UserInfo;
  setUserInfo: (val: UserInfo) => void;
  isIntern: boolean;
  setIsIntern: (val: boolean) => void;
}

export const useAppStore = create<AppState>()((set) => ({
  userInfo: undefined,
  setUserInfo: (val) => set({ userInfo: val }),
  isIntern: false,
  setIsIntern: (val: boolean) => set({ isIntern: val })
}));
