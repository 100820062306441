import { UserProfileInfo } from "@shared/models";

import { CompanyProfileInfo } from "../../../shared/companyModels";

// TODO: Make this type fancy
export const requiredFields = {
  athlete: {
    aboutMe: "About Me",
    athleticAchievements: "Athletic Achievements",
    athleticExperience: "Athletic Experience",
    athleticLevel: "Athletic Level",
    currentLocation: "Current Location",
    currentTitle: "Current Title",
    displayName: "Display Name",
    educationLevel: "Education Level",
    gpa: "GPA",
    graduationYear: "Graduation Year",
    hcaPersonalTagline: "HCA Tagline",
    highlightBullets: "AI Coach Highlights",
    linkedInUrl: "LinkedIn URL",
    major: "Major",
    phoneNumber: "Phone Number",
    primarySport: "Primary Sport",
    primaryPosition: "Primary Position",
    preferredEmployment: "Preferred Employment",
    preferredLocation: "Preferred Location(s)",
    preferredWorkEnvironment: "Preferred Work Environment",
    professionalExperience: "Professional Experience",
    readyToWork: "Ready to Work",
    universityId: "University",
  },
  employer: {
    companyName: "Company Name",
    websiteUrl: "Company Website",
    tagline: "Company Tagline",
    highlight: "Company Highlight",
    location: "Location",
    industry: "Industry",
    aboutUs: "About"
  }
};

export const calculateProfileCompletion = (profileData: UserProfileInfo | CompanyProfileInfo | undefined, profileType: "athlete" | "employer"): number => {
  if (!profileData) return 0;

  const profileFieldsToComplete = Object.keys(profileData).filter((key) => key in requiredFields[profileType]);
  const totalFields = profileFieldsToComplete.length;
  let filledFields = 0;

  for (const key of profileFieldsToComplete) {
    const value = profileData[key as keyof (UserProfileInfo | CompanyProfileInfo)];

    // Check if the field is filled based on its type
    if (
      (typeof value === "string" && value.trim() !== "") ||
      (Array.isArray(value) && value.length > 0) ||
      (typeof value === "number" && (!isNaN(value) && value > 0)) ||
      (!Array.isArray(value) && typeof value === "object" && value !== null) ||
      (typeof value === "boolean" && value === true)
    ) {
      filledFields++;
    }
  }

  return Math.round((filledFields / totalFields) * 100);
};

export const getEmptyProfileFields = (profileData: UserProfileInfo | CompanyProfileInfo, profileType: "athlete" | "employer"): { field: string; label: string }[] => {
  const emptyFields: { field: string; label: string }[] = [];

  for (const key in requiredFields[profileType]) {
    const value = profileData[key as keyof (UserProfileInfo | CompanyProfileInfo)];

    // Check if the field is empty based on its type
    if (
      (typeof value === "string" && value.trim() === "") ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "number" && isNaN(value)) ||
      (typeof value === "object" && value === null)
    ) {
      if (requiredFields[profileType]) {
        emptyFields.push({ field: key, label: requiredFields[profileType][key as keyof typeof requiredFields[typeof profileType]] });
      }
    }
  }

  return emptyFields;
};

export function simpleDebounce<T extends (...args: unknown[]) => void>(func: T, wait: number) {
  let timeout: NodeJS.Timeout | null = null;

  const executedFunction = function (...args: Parameters<T>) {
    const later = () => {
      timeout = null;
      func(...args);
    };
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, wait);
  };

  executedFunction.cancel = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  return executedFunction;
}
