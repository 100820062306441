import axios from "axios";

import { AppConfig } from "./app-config-service";
import { PendingAthleteProfile, UserProfileInfo } from "../../../../shared/models";

export const getProfile = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile`);
  return data as UserProfileInfo;
};

export const getSpecificProfile = async (userId: number) => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile/${userId}`);
  return data as UserProfileInfo;
};

export const postUserProfile = async (profileInfo: UserProfileInfo) => {
  return await axios.post(`${AppConfig.apiUrl}/api/profile`, profileInfo, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export const getPendingProfilesByDomain = async (emailDomain?: string) => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile/pending`, {
    params: { emailDomain }
  });
  return data as PendingAthleteProfile[];
}

export const copyPendingProfileToUserProfile = async (profileId: number) => {
  return await axios.post(`${AppConfig.apiUrl}/api/profile/pending`, { profileId });
}
