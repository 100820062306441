import { Icon, IconProps } from "@chakra-ui/react";

type Props = {
  color?: string;
} & IconProps;

export const HcaLogo = ({ color = "brand.primary", ...props }: Props) => (
  <Icon viewBox="0 -40 200 200" color={color} {...props} style={{ filter: color === 'white' ? "brightness(0) invert(1)" : undefined }}>
    <image
      href="/temp-200x200.png"
      x="0"
      y="-40"
      width="200"
      height="200"
    />
  </Icon>
)
