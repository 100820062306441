import { Container, VStack } from "@chakra-ui/react";


export function Page({ children, gap }: { gap?: number; children: React.ReactNode }) {

  return (
    <Container maxWidth="container.lg" p={1} pb={9}>
      <VStack w="100%" gap={gap || 3}>
        {children}
      </VStack >
    </Container>
  );
}