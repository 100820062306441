import { Card, CardHeader, CardBody, Heading, VStack, Stack, HStack } from "@chakra-ui/react";
import { UserProfileInfo } from "@shared/models";

import { useUniversity } from "../../hooks/useUniversity";
import TextWithSmallLabel from "../TextWithSmallLabel";

interface AthleteAcademicCardProps {
  profileData: UserProfileInfo;
}

const AthleteAcademicCard: React.FC<AthleteAcademicCardProps> = ({ profileData }) => {
  const { university } = useUniversity(profileData?.universityId);

  if (profileData.educationLevel.length === 0) {
    return (
      <Card size="lg" w="full">
        <CardHeader>
          <HStack>
            <Heading size='md'>Academic History</Heading>
          </HStack>
        </CardHeader>
      </Card>
    );
  }

  return (
    <Card size="lg" w="full">
      <CardHeader paddingBottom={2}>
        <Heading size='md'>Academic History</Heading>
      </CardHeader>
      <CardBody paddingTop={2}>
        <VStack gap={6} w="full">
          <Stack gap={6} direction={['column', 'row']} alignContent={"space-between"} w="full">
            <TextWithSmallLabel label="Highest Level of Education" text={profileData?.educationLevel} />

            <TextWithSmallLabel label="College / University" text={university?.universityName || ""} />
          </Stack>

          <Stack gap={6} direction={['column', 'row']} alignContent={"space-between"} w="full">
            <TextWithSmallLabel label="Major" text={profileData?.major} />

            <TextWithSmallLabel label="Graduation Year (or Expected)" text={profileData?.graduationYear || ""} />
          </Stack>

          <Stack alignContent={"space-between"} w="full">
            <TextWithSmallLabel label="GPA" text={profileData?.gpa || ""} />
          </Stack>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default AthleteAcademicCard;