import { PendingAthleteProfileDB, UniversityDb, UserFileDb, UserProfileDB } from "../apps/server/db/schema";

export enum UserRole {
  ADMIN = "admin",
  ATHLETE = "athlete",
  UNIVERSITY = "university",
  COMPANY_ADMIN = "company_admin",
  RECRUITER = "recruiter",
}

export enum AthleteRole {
  INTERN = "intern",
  ATHLETE = "athlete",
}

export enum RoutePath {
  ROOT = "/",
  ATHLETE_DASHBOARD = "/athlete",
  ATHLETE_PROFILE = "/athlete/profile",
  COACH = "/coach",
  INTERN = "/intern",

  UNIVERSITY_DASHBOARD = "/university",

  // FIND_EMPLOYERS = "/find-employers",
  // SPECIFIC_EMPLOYER_PROFILE = "/company/profile/:userId",

  // EMPLOYER_DASHBOARD = "/company",
  // EMPLOYER_PROFILE = "/company/profile",

  FIND_ATHLETES = "/find-athletes",
  SPECIFIC_ATHLETE_PROFILE = "/athlete/profile/:userId",

  ADMIN = "/admin",
  ACCOUNT = "/account",
  FEEDBACK = "/feedback",

  OUR_TEAM = "https://www.joinher.com/our-team",
}

export type RouteAccess = {
  [key in RoutePath]: (UserRole | AthleteRole)[];
};

export const routeAccess: RouteAccess = {
  [RoutePath.ROOT]: [UserRole.ATHLETE, UserRole.RECRUITER, UserRole.UNIVERSITY, UserRole.COMPANY_ADMIN],
  [RoutePath.ATHLETE_DASHBOARD]: [UserRole.ATHLETE],
  [RoutePath.ATHLETE_PROFILE]: [UserRole.ATHLETE],
  [RoutePath.COACH]: [UserRole.ATHLETE],
  [RoutePath.INTERN]: [AthleteRole.INTERN],
  [RoutePath.UNIVERSITY_DASHBOARD]: [UserRole.UNIVERSITY],
  // [RoutePath.FIND_EMPLOYERS]: [],
  // [RoutePath.EMPLOYER_DASHBOARD]: [UserRole.COMPANY_ADMIN],
  // [RoutePath.EMPLOYER_PROFILE]: [UserRole.COMPANY_ADMIN],
  [RoutePath.FIND_ATHLETES]: [UserRole.RECRUITER, UserRole.COMPANY_ADMIN],
  [RoutePath.SPECIFIC_ATHLETE_PROFILE]: [UserRole.RECRUITER, UserRole.UNIVERSITY, AthleteRole.INTERN, UserRole.COMPANY_ADMIN],
  // [RoutePath.SPECIFIC_EMPLOYER_PROFILE]: [UserRole.ADMIN],
  [RoutePath.ACCOUNT]: [UserRole.ATHLETE, UserRole.RECRUITER, UserRole.UNIVERSITY, UserRole.COMPANY_ADMIN],
  [RoutePath.FEEDBACK]: [UserRole.ATHLETE, UserRole.RECRUITER, UserRole.UNIVERSITY, UserRole.COMPANY_ADMIN],

  [RoutePath.OUR_TEAM]: [UserRole.ATHLETE, UserRole.RECRUITER, UserRole.UNIVERSITY, UserRole.COMPANY_ADMIN],
  [RoutePath.ADMIN]: [UserRole.ADMIN],
};

export enum Gender {
  MALE = "male",
  FEMALE = "female",
}

export interface UserInfo {
  id: number;
  authId: string;
  emailAddress?: string;
  role: UserRole;
  primaryUniversityId?: number;
}

export type University = Omit<UniversityDb, 'university_name'> & {
  universityName: UniversityDb['university_name'];
};

export type PendingAthleteProfile = {
  id: PendingAthleteProfileDB['id'];
  displayName: PendingAthleteProfileDB['display_name'];
  gender: PendingAthleteProfileDB['gender'];
  primarySport: PendingAthleteProfileDB['primary_sport'];
  universityId: number;
  graduationYear: number;
  emailDomain: PendingAthleteProfileDB['email_domain'];
  fileCategory: string;
  fileType: string;
  url: string;
}

export type UserProfile = {
  id: UserProfileDB['id'];
  userId: UserProfileDB['user_id'];
  displayName: UserProfileDB['display_name'];
  personalEmail: string;
  phoneNumber: UserProfileDB['phone_number'];
  linkedInUrl: UserProfileDB['linkedin_url'];
  primarySport: UserProfileDB['primary_sport'];
  primaryPosition: UserProfileDB['primary_position'];
  otherSport: UserProfileDB['other_sport'];
  otherPosition: UserProfileDB['other_position'];
  athleticLevel: UserProfileDB['level_of_play'];
  athleticExperience: UserProfileDB['athletic_years_of_experience'];
  educationLevel: UserProfileDB['level_of_education'];
  universityId: UserProfileDB['university_id'];
  major: UserProfileDB['major'];
  gender: Gender;
  graduationYear: UserProfileDB['graduation_year'];
  gpa: number;
  currentTitle: UserProfileDB['latest_job_title'];
  professionalExperience: UserProfileDB['professional_years_experience'];
  preferredWorkEnvironment: UserProfileDB['preferred_work_environment'];
  preferredEmployment: UserProfileDB['type_of_employment'];
  currentLocation: UserProfileDB['current_location'];
  preferredLocation: UserProfileDB['preferred_location'];
  readyToWork: UserProfileDB['ready_to_work'];
  aboutMe: UserProfileDB['about_me'];
  hcaPersonalTagline: UserProfileDB['hca_personal_tagline'];
  selectedHighlights: UserProfileDB['selected_highlights'];
  updatedAt: Date;
};

export type SearchQuery = {
  search?: string;
  state?: string;
  readyToWork?: boolean;
  page?: number;
  pageSize?: number;
  primarySport?: string;
  graduationYear?: number;
  universitySpecific?: boolean;
  showUnclaimedAthletes?: boolean;
  hidePendingAthletes?: boolean;
};

export interface UserProfileSearchResult extends Omit<UserProfile, 'selectedHighlights'> {
  emailAddress: string;
  pendingLogin: boolean;
  profileImageUrl?: string;
  resumeUrl?: string;
  highlightBullets: HighlightBullet[];
}

export interface UserProfileInfo extends Omit<UserProfile, 'selectedHighlights'> {
  athleticAchievements: AchievementItem[];
  highlightBullets: HighlightBullet[];
}

export interface AdminFullAthleteProfileInfo extends UserProfileInfo {
  emailAddress: string;
  universityName: string;
  profileImageMeta: UserFileDb;
  resumeMeta: UserFileDb;
  authId: boolean;
  isPending: boolean;
  isIntern: boolean;
}

export interface HighlightBullet {
  id: number;
  userId: number;
  conversationId: number;
  contentToDisplay: string;
  selected: boolean;
  updatedAt: Date;
}

export interface SportItem {
  id: number;
  sport: string;
}

export interface AchievementItem {
  id: number;
  achievement: string;
}
export interface ProfileAchievement extends AchievementItem {
  selected: boolean;
}

export interface WarmupTopic {
  id: number;
  topic: string;
  description: string;
  options: WarmupTopicOption[];
  response?: WarmupTopicResponse;
}

export interface WarmupTopicOption {
  id: number;
  option: string;
}

export interface WarmupTopicResponse {
  id?: number;
  topicId: number;
  other: string;
  optionResponses: number[];
}

export interface MagicRequest {
  sport?: string;
  major?: string;
}

export interface MagicRequest {
  sport?: string;
  major?: string;
}

export interface AiConversationStartRequest {
  displayName?: string;
}

export interface AiConversationQuickStarter {
  primarySport: string;
  primaryPosition: string;
  levelOfPlay: string;
  major: string;
  gpa: string;
  whyMajor: string;
  careerField: string;
  customPrompt?: string;
  displayName?: string;
}

export interface FileUploadMetadata {
  presignedUrl: string;
  originalFilename: string;
  remoteFileName: string;
  fileType: string;
}

export interface AiConversationUserAnswerRequest {
  content: string;
  contentToDisplay: string;
  gotoStep?: number;
}

export interface AiConversationRateRequest {
  rating: number;
}

export interface AiConversationResponse {
  lastConversation?: AiConversation;
  conversationStarter?: AiConversationQuickStarter;
}

export interface AiConversation {
  id: number;
  messages: AiConversationMessage[];
  service: string;
  model: string;
  currentStep: number;
  createdDate: Date;
  isCompleted: boolean;
  rating: number;
}

export interface AiConversationMessage {
  id: number;
  content: string;
  contentToDisplay: string;
  role: MessageRole;
  createdDate: Date;
  hideMessage?: boolean;
  tag?: string;
}

export type MessageRole = "user" | "system" | "assistant";

export const UploadCategories = ["resume", "profile-picture"] as const;
export type UploadCategory = (typeof UploadCategories)[number];

export interface UniversityAthleteCount {
  universityId: number;
  universityName: string;
  activeAthleteCount: number;
  pendingAthleteCount: number;
  totalAthleteCount: number;
}
