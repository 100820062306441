import { useState, useEffect, useRef, useCallback } from "react";

import { ColDef } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Divider,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalFooter,
  Spinner,
  useToast,
  Link,
  HStack,
  VStack,
  IconButton,
  Center,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { SubmitHandler, useForm, UseFormReturn } from "react-hook-form";

import { Industry } from "../../../../../shared/companyModels";
import {
  CompanyEditableFields,
  CompanyEditableFieldsKeysArray,
  CompanyUserProfileDB,
  CompanyUserProfileEditableFields,
  CompanyUserProfileEditableFieldsKeysArray,
  IndustryDB,
} from "../../../../server/db/schema/company.schema";
import { AwesomeHCASpinner } from "../../components/AwesomeHCASpinner";
import DynamicForm, { FormFieldConfig, FormFieldType } from "../../components/DynamicForm";
import { SearchBar } from "../../components/SearchBar";
import {
  newCompanyEditFields,
  companyDynamicFormConfig,
  newCompanyUserEditFields,
  companyUserDynamicFormConfig,
} from "../../config/dashboardFormConfigs";
import { useDebouncedResize } from "../../hooks/useDebounceResize";
import { getCompanies, postCompany, postCompanyUser } from "../../services/company-api";
import { getIndustries } from "../../services/data-api";

interface CompaniesTabProps {
  isActive: boolean;
}

interface CompanyTableData {
  id: number;
  name: string;
  website_url: string;
  industry: Industry;
  industryName: string;
  created_at: Date;
  updated_at: Date;
  searchData: string;
  company_users: CompanyUserProfileDB[];
}

const deepSearchFields: (keyof CompanyTableData)[] = ["name", "website_url", "industryName"];

const pageInfoHeight = 0.25;

type formConfigDataType = Record<
  keyof CompanyEditableFields | keyof CompanyUserProfileEditableFields,
  FormFieldConfig<
    | CompanyEditableFields[keyof CompanyEditableFields]
    | CompanyUserProfileEditableFields[keyof CompanyUserProfileEditableFields]
  >
>;

export const CompaniesTab = ({ isActive }: CompaniesTabProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isLoading, setIsLoading] = useState(true);
  const [allCompanies, setAllCompanies] = useState<CompanyTableData[]>([]);
  const [companyTableData, setCompanyTableData] = useState<CompanyTableData[] | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<CompanyTableData | null>(null);
  const [pageHeight, setPageHeight] = useState(0);
  const pageRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<AgGridReact>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formMethods = useForm<CompanyEditableFields | CompanyUserProfileEditableFields>();
  const [dynamicFormElements, setDynamicFormElements] = useState<{
    formTitle: string;
    fieldsToEdit: CompanyEditableFieldsKeysArray | CompanyUserProfileEditableFieldsKeysArray;
    formConfigData: formConfigDataType;
    formMethods: UseFormReturn<CompanyEditableFields | CompanyUserProfileEditableFields>;
    formData: CompanyEditableFields | CompanyUserProfileEditableFields;
  } | null>(null);
  const toast = useToast();
  const searchBarRef = useRef<{ clearInput: () => void }>(null);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [deepSearchText, setDeepSearchText] = useState("");

  const {
    data: companyData,
    isFetching: isLoadingCompanyData,
    refetch: refetchCompanyData,
  } = useQuery({
    queryKey: ["companyData"],
    queryFn: getCompanies,
    staleTime: 0,
  });

  useDebouncedResize(() => {
    resizePageComponents();
  }, 150);

  useEffect(() => {
    if (!companyData) return;
    const allCompaniesTemp: CompanyTableData[] = companyData.map((company) => ({
      ...company,
      industryName: company.industry.title,
      totalUsers: company.company_users.length,
      searchData: "",
    }));
    setAllCompanies(allCompaniesTemp);

    allCompaniesTemp.forEach((company) => {
      company.searchData = deepSearchFields.map((field) => company[field as keyof CompanyTableData]).join(" ");
    });

    setCompanyTableData([...allCompaniesTemp]);

    if (selectedCompany) {
      setSelectedCompany(allCompaniesTemp.find((company) => company.id === selectedCompany.id) || null);
    }

    resizePageComponents();
    setIsLoading(false);
  }, [companyData]);

  useEffect(() => {
    if (deepSearchText) {
      const deepSearchResults = allCompanies.filter((company) =>
        company.searchData.toLowerCase().includes(deepSearchText.toLowerCase()),
      );
      setCompanyTableData(deepSearchResults);
    } else {
      setCompanyTableData(allCompanies);
    }
  }, [deepSearchText]);

  useEffect(() => {
    if (isLoading || !companyData) return;
    if (companyTableData?.length === 0 && companyData?.length > 0) {
      setCompanyTableData(allCompanies);
    }
    if (pageRef.current && companyTableData) {
      resizePageComponents();
    }
  }, [companyTableData, pageRef.current, isActive]);

  const resizePageComponents = () => {
    if (tableRef.current && tableRef.current.api) {
      tableRef.current.api.sizeColumnsToFit();
    }
    if (pageRef.current) {
      const innerHeight = window.innerHeight;
      const rect = pageRef.current.getBoundingClientRect();
      setPageHeight(innerHeight - (rect.top + 50));
    }
  };

  const columnDefs: ColDef[] = [
    { headerName: "Company Name", field: "name", filter: true },
    { headerName: "Website URL", field: "website_url" },
    { headerName: "Industry", field: "industryName" },
    { headerName: "Admin", field: "admin" },
    { headerName: "Total Users", field: "totalUsers" },
    {
      headerName: "Created At",
      field: "created_at",
      filter: "agDateColumnFilter",
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      headerName: "Last Updated",
      field: "updated_at",
      filter: "agDateColumnFilter",
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
  ];

  const { data: industryData, isFetching: isLoadingIndustryData } = useQuery({
    queryKey: ["industryData"],
    queryFn: getIndustries,
    enabled: true,
    staleTime: 1000 * 60 * 50,
  });

  const { mutate: createCompany } = useMutation({
    mutationFn: postCompany,
    onSuccess: (data) => {
      toast({ title: `${data.data.name} created`, status: "success", position: "top", duration: 1000 });
      refetchCompanyData();
      onClose();
    },
    onError: (error) => {
      console.error(error);
      toast({
        title: "Error",
        description: "There was an issue creating the company",
        status: "error",
        position: "top",
        duration: 3000,
      });
      onClose();
    },
  });

  const { mutate: createCompanyUser } = useMutation({
    mutationFn: ({ companyUser, companyId }: { companyUser: CompanyUserProfileEditableFields; companyId: number }) =>
      postCompanyUser(companyUser, companyId),
    onSuccess: (data) => {
      refetchCompanyData();
      toast({ title: `${data.data.display_name} created`, status: "success", position: "top", duration: 1000 });
      onClose();
    },
    onError: (error) => {
      console.error(error);
      toast({
        title: "Error",
        description: "There was an issue creating the company user",
        status: "error",
        position: "top",
        duration: 3000,
      });
      onClose();
    },
  });

  const industrySaturatedFormConfig = (formConfigData: formConfigDataType, industryData: IndustryDB[]) => {
    let tempFormConfigData = formConfigData;
    if (industryData) {
      if (tempFormConfigData.industry_id.options && tempFormConfigData.industry_id.options?.length > 0)
        return tempFormConfigData;
      tempFormConfigData = {
        ...tempFormConfigData,
        industry_id: {
          ...tempFormConfigData.industry_id,
          options: industryData.map((industry) => ({ id: industry.id.toString(), name: industry.title })),
        },
      };
    }
    return tempFormConfigData;
  };

  const handleAddNewCompany = () => {
    const tempCompanyFormConfigData = industrySaturatedFormConfig(
      companyDynamicFormConfig as formConfigDataType,
      industryData || [],
    );
    setDynamicFormElements({
      formTitle: "Create Company",
      fieldsToEdit: newCompanyEditFields,
      formConfigData: tempCompanyFormConfigData as formConfigDataType,
      formMethods: formMethods,
      formData: {
        name: "",
        website_url: "",
        industry_id: 0,
      },
    });
    onOpen();
  };

  const handleCompanySubmit: SubmitHandler<CompanyEditableFields> = (data: CompanyEditableFields) => {
    createCompany(data);
  };

  const handleCompanyUserSubmit: SubmitHandler<CompanyUserProfileEditableFields> = (
    data: CompanyUserProfileEditableFields,
  ) => {
    if (!selectedCompany) return;
    createCompanyUser({ companyUser: data, companyId: selectedCompany.id });
  };

  const lastFormElementIsSelect = (formConfigData: formConfigDataType) => {
    if (!formConfigData) return false;
    const formConfigKeys = Object.keys(formConfigData);
    const lastKey = formConfigKeys[formConfigKeys.length - 1] as
      | keyof CompanyEditableFields
      | keyof CompanyUserProfileEditableFields;
    return formConfigData[lastKey].type === FormFieldType.SELECT;
  };

  const handleEditCompany = () => {
    if (!selectedCompany) return;
    const tempCompanyFormConfigData = industrySaturatedFormConfig(
      companyDynamicFormConfig as formConfigDataType,
      industryData || [],
    );
    setDynamicFormElements({
      formTitle: "Edit Company",
      fieldsToEdit: newCompanyEditFields,
      formConfigData: tempCompanyFormConfigData,
      formMethods: formMethods,
      formData: {
        name: selectedCompany.name,
        website_url: selectedCompany.website_url,
        industry_id: selectedCompany.industry.id,
      },
    });
    onOpen();
  };

  const handleAddCompanyUser = () => {
    setDynamicFormElements({
      formTitle: "Create Company User",
      fieldsToEdit: newCompanyUserEditFields,
      formConfigData: companyUserDynamicFormConfig as formConfigDataType,
      formMethods: formMethods,
      formData: {
        first_name: "",
        last_name: "",
        display_name: "",
        email: "",
        phone_number: "",
        title: "",
        is_admin: false,
      },
    });
    onOpen();
  };

  const handleSearch = useCallback((value: string) => {
    setQuickFilterText(value);
  }, []);

  const handleSearchChange = useCallback((value: string) => {
    setQuickFilterText(value);
  }, []);

  const handleDeepSearch = useCallback((value: string) => {
    setDeepSearchText(value);
    setQuickFilterText("");
  }, []);

  const handleDeepSearchClear = useCallback(() => {
    setDeepSearchText("");
    setQuickFilterText("");
    if (searchBarRef.current) {
      searchBarRef.current.clearInput();
    }
  }, []);

  if (!companyTableData) {
    return (
      <Box w={"full"} display={"flex"} justifyContent={"center"} alignItems={"center"} h={"100vh"}>
        <Spinner size={"lg"} speed={"1.0s"} color={"brand.500"} />
      </Box>
    );
  }

  if (isMobile) {
    return (
      <Center height="50vh">
        <Text fontSize="lg" fontWeight="medium" textAlign="center">
          We can't wait to provide an awesome mobile experience, but we aren't quite there yet. Please use a desktop to
          view this page.
        </Text>
      </Center>
    );
  }

  return (
    <Flex direction="column" h={pageHeight} ref={pageRef}>
      <Flex
        direction={{ base: "column", md: "row" }}
        p={[0, 4]}
        pr={0}
        pl={[0, 1]}
        h={[pageHeight - 10, `${pageInfoHeight * 100}%`]}
        w="100%"
        justify="space-between"
        gap={[2, 4]}
        minHeight={250}
      >
        <Flex flex={1} flexDirection="column">
          <VStack
            w="100%"
            h="100%"
            display="flex"
            justifyContent={["flex-start", "space-between"]}
            alignItems={["center", "flex-start"]}
          >
            <Button colorScheme="blue" onClick={handleAddNewCompany}>
              Add New Company
            </Button>
            <VStack w="100%" alignItems={"flex-start"}>
              {deepSearchText && (
                <Text color="brand.primary">
                  <em>
                    Filtering results on: {deepSearchText}, {companyTableData.length} results.
                  </em>
                </Text>
              )}
              <HStack w="100%" justifyContent={"space-between"}>
                <SearchBar
                  ref={searchBarRef}
                  placeholder={deepSearchText ? "Search filtered list" : "Search companies"}
                  onClick={handleSearch}
                  size="lg"
                  onChange={handleSearchChange}
                />
                {deepSearchText ? (
                  <Button size="lg" variant="solid" colorScheme="red" onClick={handleDeepSearchClear}>
                    Clear
                  </Button>
                ) : (
                  <Button
                    size="lg"
                    variant="outline"
                    colorScheme="red"
                    onClick={() => handleDeepSearch(quickFilterText)}
                  >
                    Deep Search
                  </Button>
                )}
              </HStack>
            </VStack>
          </VStack>
        </Flex>
        <Flex flex={[1]} flexDirection="column" gap={4}>
          <Flex
            direction="column"
            w="100%"
            bg={"white"}
            alignItems={"flex-start"}
            p={4}
            rounded="lg"
            gap={2}
            h="100%"
            overflow="auto"
          >
            {isLoadingCompanyData ? (
              <AwesomeHCASpinner />
            ) : selectedCompany ? (
              <>
                <HStack w="100%" justify="space-between">
                  <Link href={selectedCompany.website_url} isExternal>
                    <Heading size="md">{selectedCompany.name}</Heading>
                  </Link>
                  <IconButton
                    icon={<EditIcon fontSize="1.4rem" />}
                    aria-label="Edit"
                    variant="ghost"
                    onClick={handleEditCompany}
                  />
                </HStack>
                <Divider />
                {selectedCompany.company_users.length > 0 &&
                  selectedCompany.company_users.map((companyUser) => (
                    <Text key={companyUser.id} fontSize={"sm"}>
                      {companyUser.display_name}
                    </Text>
                  ))}
                <Button colorScheme="blue" variant="outline" size="lg" onClick={handleAddCompanyUser}>
                  Add Company User
                </Button>
              </>
            ) : (
              <Center h="100%" w="100%">
                <Heading size="md" textAlign={"center"}>
                  Select a company to view details.
                </Heading>
              </Center>
            )}
          </Flex>
        </Flex>
      </Flex>
      {companyTableData.length > 0 ? (
        <Flex h={`${(1 - pageInfoHeight) * 100}%`}>
          <div className="ag-theme-quartz" style={{ height: "100%", width: "100%" }}>
            <AgGridReact
              ref={tableRef}
              rowData={companyTableData || []}
              columnDefs={columnDefs}
              quickFilterText={quickFilterText}
              onGridReady={resizePageComponents}
              pagination={true}
              paginationPageSize={10}
              paginationPageSizeSelector={[10, 25, 50]}
              onRowClicked={(event) => {
                setSelectedCompany(allCompanies.find((company) => company.id === event.data.id) || null);
              }}
            />
          </div>
        </Flex>
      ) : (
        <Center h="100%" w="100%">
          <Heading size="md" textAlign={"center"}>
            No companies found.
          </Heading>
        </Center>
      )}
      <Box h="10px" />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={["full", "xl"]}
        closeOnOverlayClick={false}
        scrollBehavior={"inside"}
      >
        <form
          onSubmit={formMethods.handleSubmit((data) => {
            if (dynamicFormElements?.fieldsToEdit === newCompanyEditFields) {
              handleCompanySubmit(data as CompanyEditableFields);
            } else if (dynamicFormElements?.fieldsToEdit === newCompanyUserEditFields) {
              handleCompanyUserSubmit(data as CompanyUserProfileEditableFields);
            }
          })}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{dynamicFormElements?.formTitle}</ModalHeader>
            <Divider />
            <ModalCloseButton />
            {isLoadingIndustryData && <Spinner />}
            <ModalBody>
              {dynamicFormElements && (
                <DynamicForm
                  fieldsToEdit={dynamicFormElements.fieldsToEdit as never[]} // FIXME: fix this
                  formData={dynamicFormElements.formData}
                  formMethods={dynamicFormElements.formMethods}
                  formConfig={dynamicFormElements.formConfigData}
                  lastFormElementIsSelect={lastFormElementIsSelect(dynamicFormElements.formConfigData)}
                />
              )}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="brand" mr={3} type="submit" isLoading={false} loadingText="Saving">
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </Flex>
  );
};
