import React, { memo, useEffect, useState } from "react";

import { CheckCircleIcon, EditIcon } from "@chakra-ui/icons";
import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  HStack,
  VStack,
  Box,
  CircularProgress,
  CircularProgressLabel,
  Spinner,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { GoCheck } from "react-icons/go";

import { getFile, getProfileImage } from "../../services/file-api";
import { FileHandlingButton } from "../files/FileHandlingButton";

interface FileTileProps {
  profileType: "athlete" | "employer";
}

const userBasedFileRequirements: Record<
  "athlete" | "employer",
  { profileImage: { required: boolean; completed: boolean }; resume: { required: boolean; completed: boolean } }
> = {
  athlete: {
    profileImage: {
      required: true,
      completed: false,
    },
    resume: {
      required: true,
      completed: false,
    },
  },
  employer: {
    profileImage: {
      required: true,
      completed: false,
    },
    resume: {
      required: false,
      completed: false,
    },
  },
};

const FileTile: React.FC<FileTileProps> = ({ profileType }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [requirements, setRequirements] = useState(userBasedFileRequirements[profileType]);
  const [editOverride, setEditOverride] = useState(false);
  const queryClient = useQueryClient();

  const { data: profileImage } = useQuery({
    queryKey: ["profileImage"],
    queryFn: getProfileImage,
    refetchOnMount: false,
  });

  const { data: resume } = useQuery({
    queryKey: ["resume"],
    queryFn: () => getFile("resume"),
    refetchOnMount: false,
    enabled: profileType === "athlete",
  });

  useEffect(() => {
    if (profileImage?.url) {
      setRequirements((prevRequirements) => ({
        ...prevRequirements,
        profileImage: {
          ...prevRequirements.profileImage,
          completed: true,
        },
      }));
    }
    if (resume?.originalFilename) {
      setRequirements((prevRequirements) => ({
        ...prevRequirements,
        resume: {
          ...prevRequirements.resume,
          completed: true,
        },
      }));
    }
    setIsLoading(false);
  }, [profileImage, resume]);

  const handleResumeUpload = () => {
    queryClient.invalidateQueries({ queryKey: ["resume"], exact: true });
  };

  const handleProfileImageUpload = () => {
    queryClient.invalidateQueries({ queryKey: ["profileImage"], exact: true });
  };

  let profileCompletion = 0;
  const totalRequirements = Object.values(requirements).filter((requirement) => requirement.required).length;
  const completedRequirements = Object.values(requirements).filter((requirement) => requirement.completed).length;
  profileCompletion = (completedRequirements / totalRequirements) * 100;

  let completionColor = "green.500";

  if (profileCompletion < 60) {
    completionColor = "orange.400";
  }

  const ProfileComplete = ({ setEditOverride }: { setEditOverride: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const [isHovering, setIsHovering] = useState(false);
    return (
      <Card
        size="lg"
        w="full"
        border={isHovering ? "2px solid" : "none"}
        borderColor="gray.400"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        cursor="pointer"
        onClick={() => setEditOverride(true)}
      >
        <CardHeader paddingY={4} w="full">
          {isHovering ? (
            <HStack w="100%" justifyContent={"space-between"}>
              <EditIcon boxSize={10} color="gray.700" />
              <Heading textAlign={"center"} fontWeight="bold" size={["sm", "md"]} color="gray.700">
                Edit Files
              </Heading>
            </HStack>
          ) : (
            <HStack w="100%" justifyContent={"space-between"}>
              <CheckCircleIcon color="green.500" boxSize={10} />
              <Heading textAlign={"center"} fontWeight="bold" size={["sm", "md"]} color="green.500">
                Files Uploaded!
              </Heading>
            </HStack>
          )}
        </CardHeader>
      </Card>
    );
  };

  if (isLoading) {
    return (
      <Card size={["md", "lg"]} w="full">
        <CardHeader paddingY={4} w="full" display="flex" justifyContent="center" alignItems="center">
          <Spinner size="lg" color="brand.500" speed="0.7s" thickness="3px" emptyColor="gray.200" />
        </CardHeader>
      </Card>
    );
  }

  if (profileCompletion === 100 && !editOverride) {
    return <ProfileComplete setEditOverride={setEditOverride} />;
  }

  return (
    <Card size={["md", "lg"]} w="full">
      <CardHeader paddingTop={2} paddingBottom={2} w="full">
        <HStack w="full" justifyContent={"space-between"}>
          <Heading size={["md"]}>Profile Files</Heading>
          <CircularProgress value={profileCompletion} color={completionColor} capIsRound>
            <CircularProgressLabel fontSize="xs" as="b">
              {profileCompletion}%
            </CircularProgressLabel>
          </CircularProgress>
        </HStack>
      </CardHeader>
      <CardBody pt={2}>
        <VStack>
          <HStack justifyContent="space-between" w="full">
            {profileImage?.url ? (
              <Box w={9} h={9} pl={1.5}>
                <GoCheck color="green" fontSize="28" strokeWidth={0.75} />
              </Box>
            ) : (
              <Box rounded="md" w={9} h={9} border="1px solid" borderColor="gray.400" />
            )}
            <Heading size={["md"]}>{profileType === "athlete" ? "Headshot" : "Logo"}</Heading>
            <FileHandlingButton
              label={profileType === "athlete" ? "Headshot" : "CompanyLogo"}
              category={"profile-picture"}
              includeImageCropper={true}
              action="upload"
              onFileUploaded={handleProfileImageUpload}
            />
          </HStack>
          {profileType === "athlete" && (
            <HStack justifyContent="space-between" w="full">
              {resume ? (
                <Box w={9} h={9} pl={1.5}>
                  <GoCheck color="green" fontSize="28" strokeWidth={0.75} />
                </Box>
              ) : (
                // <FileHandlingButton
                //     label="Resume"
                //     category="resume"
                //     action="download"
                // />
                <Box rounded="md" w={9} h={9} border="1px solid" borderColor="gray.400" />
              )}
              <Heading size="md">Resume</Heading>
              <FileHandlingButton
                label="Resume"
                category="resume"
                action="upload"
                onFileUploaded={handleResumeUpload}
              />
              {/* {resume ? (   //TODO: I'm not sure how we want to handle deleting resume's etc.
                            <FileHandlingButton
                                label="Resume"
                                category="resume"
                                action="delete"
                                onDelete={handleResumeUpload}
                            />
                        ) : (
                            <FileHandlingButton
                                label="Resume"
                                category="resume"
                                action="upload"
                                onFileUploaded={handleResumeUpload}
                            />
                        )} */}
            </HStack>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};

export default memo(FileTile);
