import { useEffect, useState } from "react";

import {
  Box,
  Divider,
  HStack,
  VStack,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure,
  useToken,
  Flex,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import { SignedIn, useClerk, useUser } from "@clerk/clerk-react";
import { AthleteRole, RoutePath, UserRole } from "@shared/models";
import { motion } from "framer-motion";
import { BsFillPinAngleFill, BsPinAngle } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { MenuLink, SideMenuLinks, TopMenuLinks } from "./navLinks";
import { useAppStore } from "../app-store";
import { HcaLogo } from "../components/HcaLogo";
import { BrowserService } from "../services/browser-service";
import { ThemeColors } from "../styles/theme";

interface NavLinkMobileProps {
  item: MenuLink;
}

const NavLinkMobile = ({ item }: NavLinkMobileProps) => {
  return (
    <Link to={item.path} target={item.target}>
      <Box
        px={3}
        py={3}
        ml={3}
        display={"inline-block"}
        fontSize={"sm"}
        rounded={"md"}
        color={"black"}
        _hover={{
          textDecoration: "none",
          color: "black",
          bg: "gray.200",
        }}
      >
        {item.label}
      </Box>
    </Link>
  );
};

interface SideBarProps {
  menuIsVisible: boolean;
  closeMenu: () => void;
}

export default function SideBar({ menuIsVisible, closeMenu }: SideBarProps) {
  const { getButtonProps, getDisclosureProps, isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [animationComplete, setAnimationComplete] = useState(true);
  const [openAnimationCompleted, setOpenAnimationCompleted] = useState(false);
  const [allowMobileClicks, setAllowMobileClicks] = useState(false);
  const [linksForUser, setLinksForUser] = useState<MenuLink[]>([]);
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const [isIntern] = useAppStore((state) => [state.isIntern]);
  const [isLocked, setIsLocked] = useState(false);
  const [brandColor] = useToken("colors", ["brand.primary"]);
  const clerk = useClerk();
  const user = useUser();
  const navigate = useNavigate();

  const isCurrentPath = (path: string) => location.pathname === path;

  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if (userInfo?.role === UserRole.ADMIN) {
      setLinksForUser(SideMenuLinks);
    } else if (userInfo?.role === UserRole.RECRUITER) {
      setLinksForUser(SideMenuLinks.filter((link) => link.role.includes(UserRole.RECRUITER)));
    } else if (userInfo?.role === UserRole.UNIVERSITY) {
      setLinksForUser(SideMenuLinks.filter((link) => link.role.includes(UserRole.UNIVERSITY)));
    } else if (userInfo?.role === UserRole.COMPANY_ADMIN) {
      setLinksForUser(SideMenuLinks.filter((link) => link.role.includes(UserRole.COMPANY_ADMIN)));
    } else if (userInfo?.role === UserRole.ATHLETE) {
      const validLinks = SideMenuLinks.filter((link) => link.role.includes(UserRole.ATHLETE));
      if (isIntern) {
        validLinks.push(...SideMenuLinks.filter((link) => link.role.includes(AthleteRole.INTERN)));
      }
      setLinksForUser(validLinks);
    } else {
      // TODO: I don't love how we default to create athletes like this.
      const validLinks = SideMenuLinks.filter((link) => link.role.includes(UserRole.ATHLETE));
      setLinksForUser(validLinks);
    }
  }, [userInfo, isIntern]);

  useEffect(() => {
    setOpenAnimationCompleted(isOpen && animationComplete);
  }, [isOpen, animationComplete]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (menuIsVisible) {
      intervalId = setTimeout(() => {
        setAllowMobileClicks(true);
      }, 500);
    } else {
      setAllowMobileClicks(false);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setAllowMobileClicks(false);
      }
    };
  }, [menuIsVisible]);

  async function handleSignOut() {
    localStorage.removeItem("conversationFormValues");
    await clerk.signOut(() => {
      navigate(RoutePath.ROOT);
    });
  }

  function handleOnMouseOver(event: React.MouseEvent<HTMLElement>): void {
    if (BrowserService.isTouchDevice || isLocked) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    onOpen();
  }

  function handleOnMouseOut(event: React.MouseEvent<HTMLElement>): void {
    if (BrowserService.isTouchDevice || isLocked) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    onClose();
  }

  function handleLockToggle() {
    setIsLocked(!isLocked);
    if (!isLocked) {
      onOpen();
    } else {
      onClose();
    }
  }

  return (
    <Box height="100%">
      {/* For mobile view only */}
      <Drawer isOpen={menuIsVisible} placement="left" onClose={() => closeMenu()} size={"xs"}>
        <DrawerOverlay />
        <DrawerContent bg="brand.background" p={0} pt={14}>
          <DrawerCloseButton color="black" _focusVisible={{ boxShadow: "" }} />

          <DrawerBody p={0} pointerEvents={allowMobileClicks ? "all" : "none"}>
            <Box pt={5} color={"white"}>
              <VStack as={"nav"} spacing={1} align={"left"} justify={"flex-end"} px=".5rem">
                {TopMenuLinks.map((link) => (
                  <NavLinkMobile key={link.label} item={link} />
                ))}
              </VStack>
              <VStack align={"left"} pt={3} pl={0} gap={0}>
                <Divider borderColor={"gray.500"} />
                {linksForUser.map((link) => (
                  <Link to={link.path} key={link.path}>
                    <HStack
                      justify={"flex-start"}
                      cursor={"pointer"}
                      py={2.5}
                      pl={4}
                      color={ThemeColors.brand.primary}
                      bgColor={isCurrentPath(link.path) ? "brand.primary" : "inherit"}
                      position={"relative"}
                    >
                      <Box
                        pr={2}
                        fontSize={"1.6rem"}
                        position={"relative"}
                        top={"5px"}
                        left={1}
                        color={isCurrentPath(link.path) ? "white" : "brand.primary"}
                      >
                        {link.icon}
                      </Box>
                      <Text fontSize="sm" align={"center"} color={isCurrentPath(link.path) ? "white" : "black"}>
                        {link.label}
                      </Text>
                    </HStack>
                    <Divider borderColor={"gray.500"} />
                  </Link>
                ))}
              </VStack>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* For desktop view only */}
      <Box
        hideBelow={"md"}
        position={"relative"}
        bg="brand.background"
        height="100%"
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
      >
        <motion.div
          {...getDisclosureProps()}
          hidden={false}
          initial={false}
          onAnimationStart={() => setAnimationComplete(false)}
          onAnimationComplete={() => setAnimationComplete(true)}
          animate={{ width: isOpen ? 220 : 80 }}
          style={{
            backgroundColor: "brand.background",
            height: "100%",
            paddingTop: "20px",
          }}
        >
          <VStack pt={2} color={"white"} flex={1} h="100%" alignItems="flex-start" gap={0}>
            {/* Logo first */}
            <HcaLogo boxSize={16} ps={4} />

            {/* If the user is on a touch device, we'll use the space between to be an open/close button */}
            {BrowserService.isTouchDevice && <Box w="full" h="3.5rem" bg="brand.background" {...getButtonProps()} />}

            {/* Links */}
            <VStack
              w="full"
              align={"left"}
              pt={BrowserService.isTouchDevice ? 0 : "2.5rem"}
              paddingX={3}
              gap={0}
              flexGrow={1}
            >
              {linksForUser.map((link) => (
                <Link to={link.path} key={link.label}>
                  <HStack
                    justify={"flex-start"}
                    cursor={"pointer"}
                    borderRadius={7}
                    pl={".6rem"}
                    w={"full"}
                    bg={currentPath === link.path ? "brand.primary" : "inherit"}
                    _hover={currentPath === link.path ? {} : { bgColor: "brand.backgroundHover" }}
                    gap={3}
                    py={1}
                  >
                    <motion.div
                      style={{ position: "relative" }}
                      animate={{
                        left: isOpen ? 3 : 6,
                        top: isOpen ? 3 : 5,
                        fontSize: "1.6rem",
                      }}
                      initial={false}
                    >
                      <Box color={currentPath == link.path ? "white" : ThemeColors.brand.primary}>{link.icon}</Box>
                    </motion.div>
                    {openAnimationCompleted && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.2, ease: "easeOut" }}
                      >
                        <Text fontSize="sm" align={"center"} color={currentPath === link.path ? "white" : "black"}>
                          {link.label}
                        </Text>
                      </motion.div>
                    )}
                  </HStack>
                </Link>
              ))}
            </VStack>

            {/* Account menu */}
            <HStack justify={"flex-start"} w={"full"} px={3} py={3}>
              <SignedIn>
                <Flex alignItems={"center"} w={"full"}>
                  <Menu>
                    <MenuButton
                      as={Button}
                      w={"full"}
                      borderRadius={7}
                      variant={"link"}
                      cursor={"pointer"}
                      minW={0}
                      aria-label="User Menu"
                      fontWeight={"400"}
                      _hover={{ bgColor: "brand.backgroundHover" }}
                    >
                      <HStack justify={"flex-start"} cursor={"pointer"} borderRadius={7} py={3} px={3} w={"full"}>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 0.2, ease: "easeOut" }}
                        >
                          <Box color="brand.primary" fontSize={"2rem"}>
                            <CgProfile />
                          </Box>
                        </motion.div>
                        {openAnimationCompleted && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.2, ease: "easeOut" }}
                          >
                            <Text hideBelow={"md"} color="purple.600">
                              {user?.user?.firstName}
                            </Text>
                          </motion.div>
                        )}
                      </HStack>
                    </MenuButton>
                    <MenuList minW={"0"} borderColor={"gray.300"} w={200}>
                      <MenuItem onClick={() => navigate(RoutePath.ACCOUNT)} textAlign={"center"} px={6} color={"black"}>
                        Account
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem onClick={handleSignOut} px={6} color={"black"}>
                        Sign out
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
              </SignedIn>
            </HStack>
          </VStack>
        </motion.div>

        {/* Show pin icon to keep sidebar open */}
        {openAnimationCompleted && animationComplete && (
          <Box position={"absolute"} top={"10px"} right={"5px"} zIndex={1000} id="sidebar-pin-button">
            <button {...getButtonProps()} onClick={handleLockToggle}>
              <Box fontSize={"1.5rem"} color="gray.800">
                {isLocked ? (
                  <BsFillPinAngleFill style={{ color: brandColor }} />
                ) : (
                  <BsPinAngle style={{ color: brandColor }} />
                )}
              </Box>
            </button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
