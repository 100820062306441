import { useEffect, useRef, useState } from "react";

import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { AddIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, ButtonGroup, Center, Checkbox, Container, Flex, Heading, HStack, IconButton, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, Tooltip, useBreakpointValue, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Link, useLocation } from "react-router-dom";

import AthleteFocusCard from "./components/AthleteFocusCard";
import { AthleteProfilesForCompanySearch } from "../../../../../shared/athleteModels";
import { initializeAgGrid } from "../../ag-grid-setup";
import { useAppStore } from "../../app-store";
import { AwesomeHCASpinner } from "../../components/AwesomeHCASpinner";
import { SearchBar } from "../../components/SearchBar";
import { useDebouncedResize } from "../../hooks/useDebounceResize";
import useUrlParameter from "../../hooks/useUrlParameter";
import { getAllAthletes } from "../../services/search-athlete-api";

initializeAgGrid();

export interface SearchableAthlete extends AthleteProfilesForCompanySearch {
  searchField: string;
}

function buildSearchField(athlete: AthleteProfilesForCompanySearch) {
  return [
    athlete.displayName,
    athlete.emailAddress,
    athlete.linkedInUrl,
    athlete.primarySport,
    athlete.primaryPosition,
    athlete.otherSport,
    athlete.otherPosition,
    athlete.athleticLevel,
    athlete.educationLevel,
    athlete.universityName,
    athlete.major,
    athlete.currentTitle,
    athlete.preferredWorkEnvironment,
    athlete.preferredEmployment,
    athlete.currentLocation,
    athlete.preferredLocation,
    athlete.aboutMe
  ].filter(field => field && field !== "").join(" ").toLowerCase()
}

interface CellRendererParams extends ICellRendererParams {
  data: SearchableAthlete;
}

const athleteGridColumnDefs: ColDef[] = [
  {
    headerName: "Name",
    field: "displayName",
    sortable: true,
    cellRenderer: (params: CellRendererParams) => {
      const [isHovered, setIsHovered] = useState(false)
      return (
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Text>{params.data.displayName}</Text>
          {isHovered &&
            <IconButton
              as={Link}
              rounded="sm"
              to={`/athlete/profile/${params.data.athleteId}`}
              icon={<ArrowForwardIcon transform="rotate(-45deg)" fontSize="md" />}
              aria-label="Go to athlete profile"
              size="sm"
              variant="ghost"
              colorScheme="transparent"
            />
          }
        </Flex>
      )
    }
  },
  {
    headerName: "University",
    field: "universityName",
    sortable: true,
    filter: true,
    cellRenderer: (params: CellRendererParams) => {
      const [isHovered, setIsHovered] = useState(false);
      const { setUrlParameter } = useUrlParameter();
      return (
        <HStack
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Text overflow="hidden" textOverflow="ellipsis">{params.data.universityName}</Text>
          {isHovered &&
            <IconButton
              icon={<AddIcon fontSize="sm" />}
              aria-label="Filter by university"
              size="sm"
              variant="ghost"
              colorScheme="transparent"
              onClick={(event) => {
                event.preventDefault();
                setUrlParameter("university", params.data.universityName)
              }}
            />
          }
        </HStack>
      )
    }
  },
  {
    headerName: "Major",
    field: "major",
    sortable: true,
    filter: true,
    cellRenderer: (params: CellRendererParams) => {
      const [isHovered, setIsHovered] = useState(false);
      const { setUrlParameter } = useUrlParameter();
      return (
        <HStack
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          justifyContent="space-between"
        >
          <Text overflow="hidden" textOverflow="ellipsis">{params.data.major}</Text>
          {isHovered &&
            <IconButton
              icon={<AddIcon fontSize="sm" />}
              aria-label="Filter by major"
              size="sm"
              variant="ghost"
              colorScheme="transparent"
              onClick={(event) => {
                event.preventDefault();
                setUrlParameter("major", params.data.major)
              }}
            />
          }
        </HStack>
      )
    }
  },
  { headerName: "Sport", field: "primarySport", sortable: true, filter: true },
  { headerName: "GPA", field: "gpa", sortable: true, filter: 'agNumberColumnFilter', valueFormatter: (params) => params.value || "N/A" },
  { headerName: "Grad Year", field: "graduationYear", sortable: true, filter: 'agNumberColumnFilter' },
  { headerName: "Location", field: "currentLocation", sortable: true, filter: true },
  {
    headerName: "Preferred Location",
    field: "preferredLocation",
    sortable: true,
    filter: true,
    valueFormatter: (params) => params.value.join(", "),
    cellRenderer: (params: CellRendererParams) => {
      const locations = params.data.preferredLocation || [];
      if (locations.length === 0) {
        return;
      }
      return (
        <Tooltip label={locations.join(", ")} placement="top-start">
          <Box width="100%">
            <Text overflow="hidden" textOverflow="ellipsis">{locations.join(", ")}</Text>
          </Box>
        </Tooltip>
      )
    }
  },
]

interface Filter {
  text: string;
  selected: boolean;
}

interface UrlParameters {
  athlete?: string;
  ready?: "true" | "false";
  graduated?: "true" | "false";
  experience?: string;
  [key: string]: string | "true" | "false" | undefined;
}

export const FindAthletesPage = () => {
  const location = useLocation();
  const { getAllUrlParameters, clearUrlParameter, setUrlParameter, clearAllUrlParameters } = useUrlParameter();

  const isMobile = useBreakpointValue({ base: true, md: false });
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const [allAthletes, setAllAthletes] = useState<SearchableAthlete[]>([]);
  const [filteredAthletes, setFilteredAthletes] = useState<SearchableAthlete[]>([]);
  const [gridAthletes, setGridAthletes] = useState<SearchableAthlete[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [readyFilter, setReadyFilter] = useState<boolean>(false);
  const [graduatedFilter, setGraduatedFilter] = useState<boolean>(false);
  const [majorFilter, setMajorFilter] = useState<{ text: string, selected: boolean } | null>(null);
  const [universityFilter, setUniversityFilter] = useState<{ text: string, selected: boolean } | null>(null);
  const [dynamicFilterList, setDynamicFilterList] = useState<Filter[]>([]);
  const tableRef = useRef<AgGridReact>(null);
  const searchBarRef = useRef<{ clearInput: () => void }>(null);
  const [selectedAthlete, setSelectedAthlete] = useState<SearchableAthlete | null>(null);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [urlParams, setUrlParams] = useState<UrlParameters>({});
  // const [isLoadingAthlete, setIsLoadingAthlete] = useState<boolean>(false);

  useDebouncedResize(() => {
    tableRef.current?.api.sizeColumnsToFit();
  }, 100);

  const { data: athleteData, isFetching: isFetchingAthleteData } = useQuery({
    queryKey: ["athleteData"],
    queryFn: () => getAllAthletes(),
    staleTime: 1000 * 60 * 10,
  });

  useEffect(() => {
    if (!athleteData) return;
    setAllAthletes(athleteData
      .filter((athlete) => athlete.displayName !== "")
      .map((athlete) => ({
        ...athlete,
        searchField: buildSearchField(athlete),
        profilePictureUrl: null
      })));
  }, [athleteData]);

  useEffect(() => {
    if (sliderValue === 0) {
      clearUrlParameter("experience");
    } else {
      setUrlParameter("experience", sliderValue.toString());
    }
  }, [sliderValue]);

  useEffect(() => {
    if (!allAthletes) return;
    const params = getAllUrlParameters() as UrlParameters;
    const { major, university, ready, graduated, athlete, experience, ...filters } = params;
    setUrlParams(params);

    let tempAthletes = allAthletes;

    if (ready === "true") {
      tempAthletes = tempAthletes.filter((athlete) => athlete.readyToWork);
      setReadyFilter(true);
    } else {
      setReadyFilter(false);
    }

    if (graduated === "true") {
      tempAthletes = tempAthletes.filter((athlete) => athlete.graduationYear < new Date().getFullYear());
      setGraduatedFilter(true);
    } else {
      setGraduatedFilter(false);
    }

    if (major) {
      if (!majorFilter) {
        setMajorFilter({ text: major, selected: true });
        tempAthletes = tempAthletes.filter((athlete) => athlete.major.toLowerCase().includes(major.toLowerCase()));
      } else {
        if (majorFilter.text === major && majorFilter.selected) {
          tempAthletes = tempAthletes.filter((athlete) => athlete.major.toLowerCase().includes(major.toLowerCase()));
        }
      }
    }

    if (university) {
      if (!universityFilter) {
        setUniversityFilter({ text: university, selected: true });
        tempAthletes = tempAthletes.filter((athlete) => athlete.universityName.toLowerCase().includes(university.toLowerCase()));
      } else {
        if (universityFilter.text === university && universityFilter.selected) {
          tempAthletes = tempAthletes.filter((athlete) => athlete.universityName.toLowerCase().includes(university.toLowerCase()));
        }
      }
    }

    if (experience) {
      const currentYear = new Date().getFullYear();
      tempAthletes = tempAthletes.filter((athlete) => athlete.graduationYear != 0)
      tempAthletes = tempAthletes.filter((athlete) => (currentYear - athlete.graduationYear) >= parseInt(experience));
    }

    if (filters) {
      Object.keys(filters).forEach((filter) => {
        if (!dynamicFilterList.find((f) => f.text === filter)) {
          setDynamicFilterList([...dynamicFilterList, { text: filter, selected: filters[filter] === "true" }]);
        }
      });
    } else {
      setDynamicFilterList([]);
    }

    // Create a list of filters to walk through from the dynamic list and the url filters
    const allFilters = [...dynamicFilterList, ...Object.keys(filters).map((filter) => ({ text: filter, selected: filters[filter] === "true" }))].filter((filter) => filter.selected);
    const filtersToApply = [...new Set(allFilters.map((f) => f.text))];
    filtersToApply.forEach((filter) => {
      tempAthletes = tempAthletes.filter((athlete) => athlete.searchField.includes(filter.toLowerCase()));
    });
    setFilteredAthletes([...tempAthletes]);

    if (athlete && athlete !== selectedAthlete?.athleteId.toString()) {
      const athleteToFocus = allAthletes.find((ath) => ath.athleteId.toString() === athlete)
      if (athleteToFocus) {
        setSelectedAthlete(athleteToFocus)
      }
    }
  }, [location.search, allAthletes]);

  useEffect(() => {
    setGridAthletes(filteredAthletes);
  }, [filteredAthletes]);

  const handleNewFilter = () => {
    setUrlParameter(searchQuery, "true");
    setSearchQuery("");
    if (searchBarRef.current) {
      searchBarRef.current.clearInput();
    }
  }

  const clearFilters = () => {
    setDynamicFilterList([]);
    setSearchQuery("");
    clearAllUrlParameters();
    setSliderValue(0);
    setMajorFilter(null);
    setUniversityFilter(null);
    if (searchBarRef.current) {
      searchBarRef.current.clearInput();
    }
  }

  if (isMobile) {
    return (
      <Center height="100%">
        <Text fontSize="lg" fontWeight="medium" textAlign="center">We can't wait to provide an awesome mobile experience, but we aren't quite there yet. Please use a desktop to view this page.</Text>
      </Center>
    )
  }

  if (!userInfo || isFetchingAthleteData || !allAthletes) {
    return <AwesomeHCASpinner fullScreen={true} />;
  }

  return (
    <Container maxWidth="container.2xl" p={[0, 4]} height="100%">
      <Flex flexDirection="column" gap={4} height="100%">
        <Flex flexDirection={{ base: "column", lg: "row" }} gap={4} minHeight="200px">
          <Flex flexDirection="column" justifyContent="space-between" gap={2} flex={1} minWidth="600px" p={2}>
            {/* <Box display="flex" flexDirection="row" justifyContent="space-between" borderBottom="1px solid" borderColor="gray.200" pb={2}>
              <Text fontSize="lg" fontWeight="bold">Find Athletes</Text>
            </Box> */}
            <HStack height="100%">
              <VStack flex={1} height="100%" alignItems="flex-start" justifyContent="space-around" p={2}>
                <Checkbox
                  isChecked={readyFilter}
                  onChange={(e) => readyFilter ? clearUrlParameter("ready") : setUrlParameter("ready", e.target.checked.toString())}
                >
                  <Text>Ready to Work</Text>
                </Checkbox>
                <Checkbox
                  isChecked={graduatedFilter}
                  onChange={(e) => graduatedFilter ? clearUrlParameter("graduated") : setUrlParameter("graduated", e.target.checked.toString())}
                >
                  <Text>Graduated</Text>
                </Checkbox>
                {majorFilter &&
                  <Checkbox
                    key={majorFilter.text}
                    isChecked={majorFilter.selected}
                    onChange={(e) => {
                      setMajorFilter({ ...majorFilter, selected: e.target.checked })
                      if (!e.target.checked) {
                        clearUrlParameter("major");
                      } else {
                        setUrlParameter("major", majorFilter.text);
                      }
                    }}
                  >
                    <Text>{majorFilter.text}</Text>
                  </Checkbox>
                }
                {universityFilter &&
                  <Checkbox
                    key={universityFilter.text}
                    isChecked={universityFilter.selected}
                    onChange={(e) => {
                      setUniversityFilter({ ...universityFilter, selected: e.target.checked })
                      if (!e.target.checked) {
                        clearUrlParameter("university");
                      } else {
                        setUrlParameter("university", universityFilter.text);
                      }
                    }}
                  >
                    <Text>{universityFilter.text}</Text>
                  </Checkbox>
                }
              </VStack>
              <VStack flex={1} height="100%" alignItems="flex-start" p={2}>
                {dynamicFilterList.map((filter) => {
                  return (
                    <Checkbox
                      key={filter.text}
                      isChecked={filter.selected}
                      onChange={(e) => {
                        setDynamicFilterList((currentFilters) => {
                          const updatedFilters = currentFilters.map((f) => ({ ...f, selected: f.text === filter.text ? e.target.checked : f.selected }));
                          return [...updatedFilters];
                        })
                        setUrlParameter(filter.text, e.target.checked.toString());
                      }}>
                      {filter.text}
                    </Checkbox>
                  )
                })}
              </VStack>
            </HStack>
            <HStack gap={5} mb={2} alignItems="center" justifyContent="space-between">
              <Heading size="sm">Experience</Heading>
              <Slider
                min={0}
                max={15}
                colorScheme="blue"
                value={urlParams.experience ? parseInt(urlParams.experience) : sliderValue}
                onChange={(value) => setSliderValue(value)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
              <Text>{sliderValue === 15 ? "15+" : sliderValue}</Text>
            </HStack>
            <HStack width="100%" display="flex">
              <SearchBar
                ref={searchBarRef}
                size="md"
                placeholder="Search athletes..."
                onChange={(value) => setSearchQuery(value)}
                hideButton={true}
                onClick={handleNewFilter}
              />
              <ButtonGroup size="md" isAttached variant={'outline'}>
                <Button colorScheme="purple" onClick={handleNewFilter}>
                  Add Filter
                </Button>
                <Button colorScheme="red" onClick={clearFilters}>
                  Reset
                </Button>
              </ButtonGroup>
            </HStack>
          </Flex>
          <AthleteFocusCard athlete={selectedAthlete} />
        </Flex>
        <Flex flex={1} overflow="hidden">
          <Box
            width="100%"
            height="100%"
            className="ag-theme-quartz"
            style={{
              "--ag-grid-size": "6px",
            } as React.CSSProperties}
          >
            <AgGridReact
              ref={tableRef}
              onGridReady={(params) => params.api.sizeColumnsToFit()}
              onRowClicked={(event) => {
                if (event.event?.defaultPrevented) {
                  return;
                }
                setUrlParameter('athlete', event.data.athleteId.toString())
              }}
              rowData={gridAthletes}
              columnDefs={athleteGridColumnDefs}
              quickFilterText={searchQuery}
              pagination={true}
              paginationPageSize={25}
              paginationPageSizeSelector={[25, 50, 100]}
            />
          </Box>
        </Flex>
      </Flex>
    </Container>
  );
};
