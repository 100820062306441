import { useEffect, useState } from "react";

import {
  Button,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { FieldValues } from "react-hook-form";

interface MultiselectModalProps<T extends FieldValues> {
  isOpen: boolean;
  selectedItems: T[keyof T][];
  options: string[];
  accessor?: (data: T[keyof T]) => string;
  closeModal: () => void;
  onChange?: (selectedItems: T[keyof T][]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataShapeToSave?: (data: string) => any;
}

const MultiselectModal = <T extends FieldValues>({
  isOpen,
  selectedItems,
  options,
  accessor,
  closeModal,
  onChange,
  dataShapeToSave,
}: MultiselectModalProps<T>) => {
  const [localSelectedItems, setLocalSelectedItems] = useState<T[keyof T][]>([]);

  useEffect(() => {
    setLocalSelectedItems(selectedItems.map((item) => accessor ? accessor(item) : item) as T[keyof T][]);
  }, [selectedItems]);

  const handleItemSelect = (item: string) => {
    if (localSelectedItems.includes(item as unknown as T[keyof T])) {
      setLocalSelectedItems((prev) => prev.filter((i) => i !== (item as unknown as T[keyof T])));
    } else {
      setLocalSelectedItems((prev) => [...prev, item as unknown as T[keyof T]]);
    }
  };

  const handleDone = () => {
    if (onChange) {
      onChange(localSelectedItems.map((item) => dataShapeToSave ? dataShapeToSave(item) : item));
    }
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="5xl" closeOnOverlayClick={false} scrollBehavior={"inside"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Items</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Heading>Choose All That Apply!</Heading>
          <Grid templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]} gap={4} mt={8}>
            {options?.map?.((option, index) => (
              <GridItem
                key={index}
                p={2}
                bg={localSelectedItems.includes(option as unknown as T[keyof T]) ? "brand.300" : "white"}
                rounded={"xl"}
                borderWidth={1.5}
                borderColor={"black"}
                cursor={"pointer"}
                onClick={() => handleItemSelect(option)}
              >
                <Text as="h5" size="sm">
                  {option}
                </Text>
              </GridItem>
            ))}
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brand" mr={3} onClick={handleDone}>
            Done
          </Button>
          <Button onClick={closeModal}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MultiselectModal;