import { ArrowForwardIcon } from "@chakra-ui/icons";
import { VStack, HStack, Image, Center, Text, Heading, Divider, Button, Hide, Stack, Flex, Icon } from "@chakra-ui/react";

import { AthleteActionIcons } from "./AthleteActionIcons";
import { AthleteGridData } from "../types/athletes";

interface AthleteOverviewCardProps {
    athlete: AthleteGridData;
    athleteInfoHeight: number;
    isMobile: boolean;
    onNavigate: (userId: number) => void;
}

export const AthleteOverviewCard = ({
    athlete,
    athleteInfoHeight,
    isMobile,
    onNavigate
}: AthleteOverviewCardProps) => {

    return (
        <Stack direction={'row'} gap={4} h={"100%"} p={3}>
            <VStack h={"100%"}>
                <Flex flex={2} flexDir={"column"} alignItems={"center"} h={"100%"} w={["auto"]} justifyContent={["center"]} px={[0]} gap={2}>
                    <Flex>
                        {athlete.profilePictureUrl ? (
                            <Image
                                src={athlete.profilePictureUrl}
                                alt="Profile Picture"
                                borderRadius="md"
                                height={[200, athleteInfoHeight * 0.75]}
                                maxWidth="100%"
                            />
                        ) : (
                            <Center height={200} width={200} borderRadius="md" border="1px dashed black">
                                <Text>No profile picture</Text>
                            </Center>
                        )}
                    </Flex>
                    <AthleteActionIcons
                        athlete={athlete}
                        direction={isMobile ? "column" : "row"}
                    />
                </Flex>
            </VStack>

            <VStack flex={1} alignItems="flex-start" justifyContent="flex-start" h='100%'>
                <HStack alignItems="center" justifyContent={["space-between"]} w="full">
                    <Heading size="lg">{athlete.displayName}</Heading>
                    <Button
                        borderRadius="full"
                        pl={[0, 6]}
                        pr={[1, 5]}
                        variant="outline"
                        colorScheme='gray'
                        size='sm'
                        fontWeight='normal'
                        onClick={() => onNavigate(athlete.userId)}
                    >
                        <Hide below="md">
                            View Profile
                        </Hide>
                        <Icon ml={1} as={ArrowForwardIcon} transform="rotate(-45deg)" />
                    </Button>
                </HStack>
                <Divider />
                <Text fontSize={["md"]}>
                    {[athlete.graduationYear, athlete.universityName, athlete.major].filter(Boolean).join(" - ")}
                </Text>
                <Text fontSize={["md"]}>
                    {[athlete.primarySport, athlete.primaryPosition].filter(Boolean).join(" - ")}
                </Text>
                <Text fontSize={["md"]}>
                    {[athlete.emailAddress, athlete.phoneNumber].filter(Boolean).join(" | ")}
                </Text>
                <Text fontSize={["md"]}>
                    {athlete.currentLocation &&
                        <>
                            <strong>Current Location:</strong> {athlete.currentLocation}
                        </>
                    }
                </Text>
                <Text fontSize={["md"]}>
                    {athlete.preferredLocation.length > 0 &&
                        <>
                            <strong>Preferred Locations:</strong> {athlete.preferredLocation.join(", ")}
                        </>
                    }
                </Text>
            </VStack>
        </Stack>
    );
}; 