import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  HStack,
  Text,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider
} from "@chakra-ui/react";
import { SignedIn, useUser } from "@clerk/clerk-react";
import { useClerk } from "@clerk/clerk-react";
import { motion } from "framer-motion";
import { CgProfile } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

import { RoutePath } from "../../../../shared/models";
import { ThemeColors } from "../styles/theme";

interface NavBarProps {
  menuIsVisible: boolean;
  openMenu: () => void;
}

export default function NavBar({ menuIsVisible, openMenu }: NavBarProps) {
  const clerk = useClerk();
  const user = useUser();
  const navigate = useNavigate();

  const menuVariants = {
    open: { rotate: 90 },
    closed: { rotate: 0 }
  };

  async function handleSignOut() {
    localStorage.removeItem("conversationFormValues");
    await clerk.signOut(() => {
      location.href = "/signin";
    });
  }

  return (
    <Box px={4} w={"100%"} bg={"brand.background"}>
      <Flex h={12} alignItems={"center"} justifyContent={"flex-end"}>
        <Box w={"32px"} h={2} hideFrom={"md"}></Box>
        <Box position="absolute" display={{ md: "none" }} left={2}>
          <motion.div
            animate={menuIsVisible ? "open" : "closed"}
            variants={menuVariants}
            transition={{ duration: 0.25 }}
          >
            <IconButton
              aria-label={"Open menu"}
              icon={
                menuIsVisible ? (
                  <CloseIcon color={"white"} bg={ThemeColors.brand.primary} fontSize={"md"} />
                ) : (
                  <HamburgerIcon bg={"transparent"} />
                )
              }
              // onTouchStart={() => openMenu()}
              onClick={() => openMenu()}
              fontSize={"22px"}
              variant={""}
            />
          </motion.div>
        </Box>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: clerk.loaded ? 1 : 0 }} transition={{ duration: 0.5 }}>
          <HStack>
            <SignedIn>
              <Flex alignItems={"center"}>
                <Menu>
                  <MenuButton
                    as={Button}
                    rounded={"full"}
                    variant={"link"}
                    cursor={"pointer"}
                    minW={0}
                    aria-label="User Menu"
                    fontWeight={"400"}
                    _hover={{ textDecoration: "none" }}
                  >
                    <HStack>
                      <Text hideBelow={"md"} color="purple.600">
                        {user?.user?.firstName}
                      </Text>

                      <Box color="brand.primary" fontSize={"2rem"}>
                        <CgProfile />
                      </Box>
                    </HStack>
                  </MenuButton>
                  <MenuList minW={"0"} borderColor={"gray.300"}>
                    <MenuItem onClick={() => navigate(RoutePath.ACCOUNT)} textAlign={"center"} px={6}>
                      Account
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={handleSignOut} px={6}>
                      Sign out
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </SignedIn>
          </HStack>
        </motion.div>
      </Flex>
    </Box>
  );
}
