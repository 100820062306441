import { Card, CardHeader, CardBody, Heading, VStack, Stack, Box, Flex, Text, HStack } from "@chakra-ui/react";
import { UserProfileInfo } from "@shared/models";

import TextWithSmallLabel from "../TextWithSmallLabel";

interface AthleteAthleticCardProps {
  profileData: UserProfileInfo;
}

const AthleteAthleticCard: React.FC<AthleteAthleticCardProps> = ({ profileData }) => {

  if (profileData.primarySport.length === 0) {
    return (
      <Card size="lg" w="full">
        <CardHeader>
          <HStack>
            <Heading size='md'>Athletic Background</Heading>
          </HStack>
        </CardHeader>
      </Card>
    );
  }

  return (
    <Card size="lg" w="full">
      <CardHeader paddingBottom={2}>
        <Heading size='md'>Athletic Background</Heading>
      </CardHeader>
      <CardBody paddingTop={2}>
        <VStack gap={6} w="full">
          <Stack gap={6} direction={['column', 'row']} alignContent={"space-between"} w="full">
            <TextWithSmallLabel label="Primary Sport" text={profileData?.primarySport} />

            <TextWithSmallLabel label="Primary Position" text={profileData?.primaryPosition} />
          </Stack>

          <Stack gap={6} direction={['column', 'row']} alignContent={"space-between"} w="full">
            <TextWithSmallLabel label="Highest Level Played" text={profileData?.athleticLevel} />

            <TextWithSmallLabel label="Athletic Experience" text={profileData?.athleticExperience} tooltipLabel="Number of years at the highest level" />
          </Stack>

          <Stack alignContent={"space-between"} w="full">
            <Heading size='sm'>Athletic Highlights</Heading>
            <Box>
              <Flex gap={3} mb={3} wrap={"wrap"}>
                {profileData.athleticAchievements.map((item, index: number) => (
                  <Text key={index} size="sm" p={1} px={4} rounded={"xl"} bg={"brand.100"}>
                    {item.achievement}
                  </Text>
                ))}
              </Flex>
            </Box>
          </Stack>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default AthleteAthleticCard;