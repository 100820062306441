import { useEffect, useState } from "react";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import { HStack, Image, Center, Text, Heading, Box, Button, Icon, Flex, VStack } from "@chakra-ui/react";
import axios from "axios";
import { Select } from "chakra-react-select";
import { useNavigate } from "react-router-dom";

import { AthleteActionIcons } from "./AthleteActionIcons";
import { SearchBar } from "../../../components/SearchBar";
import useUrlParameter from "../../../hooks/useUrlParameter";
import { AthleteGridData } from "../types/athletes";

interface MobileAthletesViewProps {
    athletes: AthleteGridData[];
    universityOptions: { label: string; value: string; }[];
    setUrlParameter: (key: string, value: string) => void;
    urlParameters: {
        university?: string;
    };
}

export const MobileAthletesView = ({
    athletes,
    universityOptions,
    urlParameters,
}: MobileAthletesViewProps) => {
    const navigate = useNavigate();
    const { setUrlParameter } = useUrlParameter();
    const [mobileSearchText, setMobileSearchText] = useState("");
    const [athleteImages, setAthleteImages] = useState<{ [key: number]: string }>({})

    useEffect(() => {
        athletes.forEach(async (athlete) => {
            if (athleteImages[athlete.userId]) return;
            if (athlete.profileImageMeta && athlete.profileImageMeta.stored_file_name) {
                const url = await axios.post("/api/file/presigned-open-url", {
                    category: "profile-picture",
                    remoteFileName: athlete.profileImageMeta.stored_file_name
                })
                setAthleteImages((prev) => ({ ...prev, [athlete.userId]: url.data.presignedUrl }))
            }
        })
    }, [athletes])

    const filteredAthletes = athletes.filter((athlete) => athlete.searchData.toLowerCase().includes(mobileSearchText.toLowerCase()))

    return (
        <Flex direction="column" w="100%">
            <Box p={1} pt={0}>
                <Select
                    size="md"
                    options={universityOptions}
                    placeholder="Filter by university"
                    onChange={(e) => setUrlParameter('university', e?.value || "Any")}
                    value={urlParameters.university ? { label: urlParameters.university, value: urlParameters.university } : null}
                />
            </Box>
            <Box p={1}>
                <SearchBar
                    placeholder={"Search for athletes"}
                    size="md"
                    onChange={(value) => setMobileSearchText(value)}
                    hideButton={true}
                />
            </Box>
            <Text p={1} alignSelf="flex-end" fontStyle="italic" fontSize="sm" fontWeight="medium" color="gray.500">Total athletes: {filteredAthletes.length}</Text>
            <VStack gap={2} flex={1}>
                {filteredAthletes.map((athlete, index) => (
                    <HStack
                        key={index}
                        w="100%"
                        gap={2}
                        alignItems="flex-start"
                        bg="white"
                        p={2}
                        borderRadius="md"
                        shadow="md"
                    >
                        <Flex direction="column" gap={2}>
                            {athleteImages[athlete.userId] ? (
                                <Image
                                    src={athleteImages[athlete.userId]}
                                    alt="Profile Picture"
                                    borderRadius="md"
                                    height={100}
                                    width={100}
                                    objectFit="cover"
                                    loading="lazy"
                                />
                            ) : (
                                <Center height={100} width={100} borderRadius="md" border="1px dashed black">
                                    <Text fontSize="12px">No image</Text>
                                </Center>
                            )}
                            <Button
                                borderRadius="full"
                                pl={[0, 6]}
                                pr={[1, 5]}
                                variant="outline"
                                colorScheme='purple'
                                size='sm'
                                fontWeight='normal'
                                onClick={() => navigate(`/athlete/profile/${athlete.userId}`)}
                            >
                                <Icon ml={1} as={ArrowForwardIcon} transform="rotate(-45deg)" fontSize="20px" />
                            </Button>
                        </Flex>
                        <Flex direction="column" flex={1} gap={1} minW={0}>
                            <Heading size="sm" noOfLines={1}>{athlete.displayName}</Heading>
                            <Text fontSize="xs">{athlete.universityName || "No university"} - {athlete.major || "No major"}</Text>
                            <Text fontSize="xs"><b>Current Location:</b> {athlete.currentLocation}</Text>
                            <Text fontSize="xs"><b>Preferred Locations:</b> {athlete.preferredLocation.join(", ")}</Text>
                        </Flex>
                        <Flex py={1}>
                            <AthleteActionIcons athlete={athlete} direction="column" />
                        </Flex>
                    </HStack>
                ))}
            </VStack>
        </Flex>
    );
};
