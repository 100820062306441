import { EditIcon } from "@chakra-ui/icons";
import { Box, IconButton } from "@chakra-ui/react";

type EditButtonWrapperProps = {
  children: React.ReactNode;
  onEdit: () => void;
};

const EditButtonWrapper: React.FC<EditButtonWrapperProps> = ({ children, onEdit }) => {
  return (
    <Box position="relative" w="full">
      <IconButton
        isRound={true}
        variant='outline'
        colorScheme='brand'
        aria-label='Edit'
        fontSize='14px'
        size="sm"
        icon={<EditIcon />}
        position="absolute"
        zIndex={2}
        right={3}
        top={3}
        bgColor="white"
        onClick={onEdit}
      />
      {children}
    </Box>
  );
};

export default EditButtonWrapper;