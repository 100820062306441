
import axios from "axios";

import { AppConfig } from "./app-config-service";
import { Industry } from "../../../../shared/companyModels";

export const getIndustries = async () => {
    const { data } = await axios.get(`${AppConfig.apiUrl}/api/data/industries`);
    return data as Industry[];
};
