import { AchievementItem, SportItem } from "@shared/models";
import axios from "axios";

import { AppConfig } from "./app-config-service";

export const getSports = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/sports`);
  return data as SportItem[];
};

export const getPositionBySportId = async (sportId: number) => {
  const { data } = await axios.post(`${AppConfig.apiUrl}/api/positions`, { sportId });
  return data as string[];
};

export const getAchievements = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/achievements`);
  return data as AchievementItem[];
};

