import { Card, CardHeader, CardBody, Heading, Stack, HStack } from "@chakra-ui/react";
import { UserProfileInfo } from "@shared/models";

import TextWithSmallLabel from "../TextWithSmallLabel";

interface AthleteCareerInfoProps {
  profileData: UserProfileInfo;
  editMode?: boolean
}

const AthleteCareerCard: React.FC<AthleteCareerInfoProps> = ({ profileData, editMode }) => {

  if (profileData.professionalExperience === 0) {
    return (
      <Card size="lg" w="full">
        <CardHeader>
          <HStack>
            <Heading size='md'>Career Info</Heading>
          </HStack>
        </CardHeader>
      </Card>
    );
  }
  return (
    <Card size="lg" w="full">
      <CardHeader paddingBottom={2}>
        <Heading size='md'>Career Info</Heading>
      </CardHeader>
      <CardBody paddingTop={2}>
        <Stack gap={6} direction={['column', 'row']} alignContent={"space-between"}>
          {
            (profileData?.currentTitle || editMode) && (
              <TextWithSmallLabel label="Latest Job Title" text={profileData?.currentTitle} />
            )
          }

          {
            (profileData?.professionalExperience || editMode) && (
              <TextWithSmallLabel
                label="Years of Experience"
                text={profileData?.professionalExperience === 1 ? `1 year` : `${profileData?.professionalExperience} years`}
              />
            )
          }
        </Stack>
      </CardBody>
    </Card>
  );
};

export default AthleteCareerCard;